@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AcuminPro-Semibold";
  src: url("../public/fonts/AcuminPro-Semibold.eot");
  src: local("☺"), url("../public/fonts/AcuminPro-Semibold.woff") format("woff"),
    url("../public/fonts/AcuminPro-Semibold.ttf") format("truetype"),
    url("../public/fonts/AcuminPro-Semibold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AcuminPro-Bold";
  src: url("../public/fonts/AcuminPro-Bold.eot");
  src: local("☺"), url("../public/fonts/AcuminPro-Bold.woff") format("woff"),
    url("../public/fonts/AcuminPro-Bold.ttf") format("truetype"),
    url("../public/fonts/AcuminPro-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AcuminPro-Black";
  src: url("../public/fonts/AcuminPro-Black.eot");
  src: local("☺"), url("../public/fonts/AcuminPro-Black.woff") format("woff"),
    url("../public/fonts/AcuminPro-Black.ttf") format("truetype"),
    url("../public/fonts/AcuminPro-Black.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AcuminPro-Regular";
  src: url("../public/fonts/AcuminPro-Regular.eot");
  src: local("☺"), url("../public/fonts/AcuminPro-Regular.woff") format("woff"),
    url("../public/fonts/AcuminPro-Regular.ttf") format("truetype"),
    url("../public/fonts/AcuminPro-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@layer base {
  :root {
    --primaryColor: #5348d4;
    --primaryColorDark: #3f35b8;
    --successCarpet: #24bd63;
    --successDarkCarpet: #1da053;
    --dangerCarpet: #d32f2f;
    --dangerDarkCarpet: #b72626;
    --warningCarpet: #f78310;
    --warningDarkCarpet: #d5700d;
    --bgSurface: #f5f6fa;
    --formElementsBG: #f5f6fa;
    --hoverLightColor: #edeef5;
    --textDark: #374856;
    --textExtraLight: #BCB9DC;
    --textSecondary:#585b5d;
    --textTertiary:#9b9d9e;
    --borderPrimary: #ccd0de;
    --borderPrimaryLight: #e9ebf4;
    --textWhiteCarpet: #ffffff;
    --textBlackCarpet: #000000;
    --borderWhiteCarpet: #ffffff;
    --borderBlackCarpet: #000000;
    --bgWhiteCarpet: #ffffff;
    --bgBlackCarpet: #000000;
    --raiseShadow: 1px 1px 3px 0px hsla(0, 0%, 81%, 0.9),
      -1px -1px 2px 0px hsla(0, 0%, 100%, 0.9),
      1px -1px 2px 0px hsla(0, 0%, 81%, 0.2),
      -1px 1px 2px 0px hsla(0, 0%, 81%, 0.2);
    --dropDownShadow: 0px 3px 20px #00000029;
    --mediumFloatShadow:0px 0px 0px 0px transparent,4px 5px 14px 0px transparent,5px 8px 20px 0px rgba(0,0,0,.06),37px 45px 35px 0px rgba(0,0,0,.02),65px 85px 41px 0px rgba(0,0,0,.01),102px 125px 45px 0px transparent;
    --highFloatShadow:0px 0px 0px 0px rgba(0,0,0,.04),4px 5px 14px 0px rgba(0,0,0,.04),16px 20px 26px 0px rgba(0,0,0,.04),37px 25px 30px 0px rgba(0,0,0,.02),66px 30px 35px 0px rgba(0,0,0,.01);
  }

  /* comman-css-start */
  html,
  body {
    @apply m-0 p-0 text-[16px] font-AcuminPro__Regular text-textDark;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @apply mt-0;
  }
  * {
    @apply box-border break-words;
  }
  img {
    @apply inline-block max-w-full;
  }
  a {
    @apply inline-block text-primaryColor underline hover:underline;
  }
  .disabled,
  .disable{
    @apply opacity-50 pointer-events-none;
  }

  /* .fancyScroll,
  .selectCarpet .selectCarpet__menu-list,
  .listing__table .rdt_Table .rdt_TableBody,
  .react-datepicker .react-datepicker__year-dropdown,
  .react-datepicker .react-datepicker__time-list,
  .modal__wrapper__carpet .modal__body{
    scrollbar-color:#5348d4 #ebebeb;
    scrollbar-width: thin;
  } */
  .fancyScroll::-webkit-scrollbar,
  .selectCarpet .selectCarpet__menu-list::-webkit-scrollbar,
  .rdt_Table .rdt_TableBody::-webkit-scrollbar,
  .react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar,
  .react-datepicker .react-datepicker__time-list::-webkit-scrollbar,
  .modal__wrapper__carpet .modal__body::-webkit-scrollbar,
  .arAgingReport__modal .responsiveDataTable::-webkit-scrollbar{
    width:6px;
  }
  .fancyScroll::-webkit-scrollbar-track,
  .selectCarpet .selectCarpet__menu-list::-webkit-scrollbar-track,
  .rdt_Table .rdt_TableBody::-webkit-scrollbar-track,
  .react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar-track,
  .react-datepicker .react-datepicker__time-list::-webkit-scrollbar-track,
  .modal__wrapper__carpet .modal__body::-webkit-scrollbar-track{
    border-radius:50px;
    background-color: #e7e7e7;
    /* border: 1px solid #cacaca; */
    box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
  }
  .fancyScroll::-webkit-scrollbar-thumb,
  .selectCarpet .selectCarpet__menu-list::-webkit-scrollbar-thumb,
  .rdt_Table .rdt_TableBody::-webkit-scrollbar-thumb,
  .react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar-thumb,
  .react-datepicker .react-datepicker__time-list::-webkit-scrollbar-thumb,
  .modal__wrapper__carpet .modal__body::-webkit-scrollbar-thumb{
    background: var(--primaryColor);
    border-radius:50px;
  }
  /* .fancyScroll::-webkit-scrollbar-thumb:hover,
  .selectCarpet .selectCarpet__menu-list::-webkit-scrollbar-thumb:hover,
  .listing__table .rdt_Table .rdt_TableBody::-webkit-scrollbar-thumb:hover,
  .react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar-thumb:hover,
  .react-datepicker .react-datepicker__time-list::-webkit-scrollbar-thumb:hover,
  .modal__wrapper__carpet .modal__body::-webkit-scrollbar-thumb:hover{
    background: var(--hoverLightColor);
  } */
  .arAgingReport__modal .responsiveDataTable::-webkit-scrollbar{
    height:6px;
  }
  .arAgingReport__modal .responsiveDataTable::-webkit-scrollbar-track{
    border-radius:50px;
    background-color: #e7e7e7;
    /* border: 1px solid #cacaca; */
    box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
  }
  .arAgingReport__modal .responsiveDataTable::-webkit-scrollbar-thumb{
    background: var(--primaryColor);
    border-radius:50px;
  }


  *:not(.responsiveDataTable):-webkit-scrollbar {
    height: 6px;
  }
  *:not(.responsiveDataTable)::-webkit-scrollbar-track {
    border-radius:50px;
  }
  *:not(.responsiveDataTable)::-webkit-scrollbar-thumb {
    border-radius:50px;
  }

  .hideScrollbar::-webkit-scrollbar {
    display: none;
  }
  .hideScrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .overlayScreen{
    @apply h-[100vh] fixed top-0 bottom-0 left-0 right-0 w-screen bg-[#2726354d] z-[14] hidden;
  }

  /* carpetAnimated__Logo */
  .carpetAnimated__logo .letter6{
    stroke: #3f5493;
    stroke-width: 2px;
    fill: #ffffff;
    stroke-dasharray: 370;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: letter6;
    animation-timing-function: linear;
  }
  @keyframes letter6 {
    0% {
      stroke-dashoffset: 0;
      fill: #ffffff;
    }
    30% {
      stroke-dashoffset: 370;
      fill: #ffffff;
    }
    56% {
      stroke-dashoffset: 740;
      fill: #ffffff;
    }
    100% {
      stroke-dashoffset: 740;
      fill: transparent;
    }
  }
  .carpetAnimated__logo .letter5{
    stroke: #3f5493;
    stroke-width: 2px;
    fill: #ffffff;
    stroke-dasharray: 480;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: letter5;
    animation-timing-function: linear;
  }
  @keyframes letter5 {
    0% {
      stroke-dashoffset: 0;
      fill: #ffffff;
    }
    30% {
      stroke-dashoffset: 480;
      fill: #ffffff;
    }
    56% {
      stroke-dashoffset: 960;
      fill: #ffffff;
    }
    100% {
      stroke-dashoffset: 960;
      fill: transparent;
    }
  }
  .carpetAnimated__logo .letter1{
    stroke: #3f5493;
    stroke-width: 2px;
    fill: #ffffff;
    stroke-dasharray: 260;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: letter1;
    animation-timing-function: linear;
  }
  @keyframes letter1 {
    0% {
      stroke-dashoffset: 0;
      fill: #ffffff;
    }
    30% {
      stroke-dashoffset: 260;
      fill: #ffffff;
    }
    56% {
      stroke-dashoffset: 520;
      fill: #ffffff;
    }
    100% {
      stroke-dashoffset: 520;
      fill: transparent;
    }
  }
  .carpetAnimated__logo .letterC,
  .carpetAnimated__logo .letterA,
  .carpetAnimated__logo .letterR,
  .carpetAnimated__logo .letterP,
  .carpetAnimated__logo .letterE,
  .carpetAnimated__logo .letterT,
  .carpetAnimated__logo .letterS{
    fill:transparent;
  }
  .carpetAnimated__logo .letterCBehind{
    stroke: #8f99cd;
    stroke-width: 1.5px;
    fill: transparent;
    stroke-dasharray: 235;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: letterCBehind;
    animation-timing-function: linear;
  }
  @keyframes letterCBehind {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    30% {
      stroke-dashoffset: 235;
      fill: transparent;
    }
    56% {
      stroke-dashoffset: 470;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 470;
      fill: #8f99cd;
    }
  }
  .carpetAnimated__logo .letterABehind{
    stroke: #8f99cd;
    stroke-width: 1.5px;
    fill: transparent;
    stroke-dasharray: 172;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: letterABehind;
    animation-timing-function: linear;
  }
  @keyframes letterABehind {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    30% {
      stroke-dashoffset: 172;
      fill: transparent;
    }
    56% {
      stroke-dashoffset: 344;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 344;
      fill: #8f99cd;
    }
  }
  .carpetAnimated__logo .letterRBehind{
    stroke: #8f99cd;
    stroke-width: 1.5px;
    fill: transparent;
    stroke-dasharray: 140;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: letterRBehind;
    animation-timing-function: linear;
  }
  @keyframes letterRBehind {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    30% {
      stroke-dashoffset: 140;
      fill: transparent;
    }
    56% {
      stroke-dashoffset: 280;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 280;
      fill: #8f99cd;
    }
  }
  .carpetAnimated__logo .letterPBehind{
    stroke: #8f99cd;
    stroke-width: 1.5px;
    fill: transparent;
    stroke-dasharray: 205;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: letterPBehind;
    animation-timing-function: linear;
  }
  @keyframes letterPBehind {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    30% {
      stroke-dashoffset: 205;
      fill: transparent;
    }
    56% {
      stroke-dashoffset: 410;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 410;
      fill: #8f99cd;
    }
  }
  .carpetAnimated__logo .letterEBehind{
    stroke: #8f99cd;
    stroke-width: 1.5px;
    fill: transparent;
    stroke-dasharray: 165;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: letterEBehind;
    animation-timing-function: linear;
  }
  @keyframes letterEBehind {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    30% {
      stroke-dashoffset: 165;
      fill: transparent;
    }
    56% {
      stroke-dashoffset: 330;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 330;
      fill: #8f99cd;
    }
  }
  .carpetAnimated__logo .letterTBehind{
    stroke: #8f99cd;
    stroke-width: 1.5px;
    fill: transparent;
    stroke-dasharray: 165;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: letterTBehind;
    animation-timing-function: linear;
  }
  @keyframes letterTBehind {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    30% {
      stroke-dashoffset: 165;
      fill: transparent;
    }
    56% {
      stroke-dashoffset: 330;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 330;
      fill: #8f99cd;
    }
  }
  .carpetAnimated__logo .letterSBehind{
    stroke: #8f99cd;
    stroke-width: 1.5px;
    fill: transparent;
    stroke-dasharray: 170;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: letterSBehind;
    animation-timing-function: linear;
  }
  @keyframes letterSBehind {
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    30% {
      stroke-dashoffset: 170;
      fill: transparent;
    }
    56% {
      stroke-dashoffset: 340;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 340;
      fill: #8f99cd;
    }
  }
  .carpetAnimated__logo .plusIcon{
    stroke: #f19900;
    stroke-width: 1.5px;
    fill: transparent;
    stroke-dasharray: 175;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: plusIcon;
    animation-timing-function: linear;
  }
  @keyframes plusIcon{
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    30% {
      stroke-dashoffset: 175;
      fill: transparent;
    }
    56% {
      stroke-dashoffset: 350;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 350;
      fill: #f19900;
    }
  }
  .carpetAnimated__logo .bottomText{
    stroke: #f19900;
    stroke-width: 1.5px;
    fill: transparent;
    stroke-dasharray: 90;
    stroke-dashoffset: 0;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: bottomText;
    animation-timing-function: linear;
  }
  @keyframes bottomText{
    0% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    30% {
      stroke-dashoffset: 90;
      fill: transparent;
    }
    56% {
      stroke-dashoffset: 180;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 180;
      fill: #f19900;
    }
  }
  /* carpetAnimated__Logo end */

  /* tags__badge */
  .tags__badge {
    @apply inline-flex justify-center items-center max-w-full pt-[5px] pb-[3px] px-[16px] overflow-hidden rounded-[100px] relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-textDark before:opacity-10;
  }
  .tags__badge .tags__text {
    @apply inline-block w-full whitespace-pre overflow-hidden text-ellipsis text-[14px] font-AcuminPro__Regular text-textDark;
  }
  .tags__badge .close__btn{
    @apply w-[22px] h-[22px] cursor-pointer ml-[7px] flex shrink-0 relative top-[1px] mr-[-10px];
  }
  .tags__badge:hover .close__btn{
    @apply flex;
  }
  .tags__badge .close__btn .iconWrapper{
    @apply before:rounded-full hover:before:bg-bgWhiteCarpet p-[3px] rounded-full w-[18px] h-[18px];
  }
  .tags__badge.tags__badgePrimary{
    @apply before:bg-primaryColor;
  }
  .tags__badge.tags__badgePrimary .tags__text{
    @apply text-primaryColor;
  }
  .tags__badge.tags__badgePrimary .close__btn .iconWrapper svg{
    @apply fill-primaryColor;
  }
  .tags__badge.tags__badgeSuccess{
    @apply before:bg-successCarpet;
  }
  .tags__badge.tags__badgeSuccess .tags__text{
    @apply text-successCarpet;
  }
  .tags__badge.tags__badgeSuccess .close__btn .iconWrapper svg{
    @apply fill-successCarpet;
  }
  .tags__badge.tags__badgeDanger{
    @apply before:bg-dangerCarpet;
  }
  .tags__badge.tags__badgeDanger .tags__text{
    @apply text-dangerCarpet;
  }
  .tags__badge.tags__badgeDanger .close__btn .iconWrapper svg{
    @apply fill-dangerCarpet;
  }
  .tags__badge.tags__badgeWarning{
    @apply before:bg-warningCarpet;
  }
  .tags__badge.tags__badgeWarning .tags__text{
    @apply text-warningCarpet;
  }
  .tags__badge.tags__badgeWarning .close__btn .iconWrapper svg{
    @apply fill-warningCarpet;
  }
  /* tags__badge end */

  /* smallLoader */
  .smallLoaderWrapper{
    @apply inline-flex items-center justify-center;
  }
  .smallLoader{
    @apply inline-block w-0 h-0 p-[7px] rounded-full border-[3px] border-gray-300 border-r-primaryColor relative animate-smalLoaderCarpet;
  }
  /* smallLoader end */

  /* smallLoader */
  .pageLoaderWrapper{
    @apply inline-flex items-center justify-center bg-white rounded-full p-[6px] shadow-raiseShadow;
  }
  .pageLoader{
    @apply inline-block w-0 h-0 p-[10px] rounded-full border-[4px] border-gray-300 border-r-primaryColor relative animate-smalLoaderCarpet;
  }
  /* smallLoader end */

  /* iconWrapper */
  .iconWrapper {
    @apply flex items-center justify-center overflow-hidden;
  }
  .iconWrapper .svgWrapper {
    @apply h-full w-full relative z-[2];
  }
  .iconWrapper .svgWrapper svg {
    @apply w-full h-full fill-textDark duration-300;
  }

  .iconWrapper.lightToDarkHover{
    @apply before:bg-primaryColor before:opacity-10 hover:before:opacity-100;
  }
  .iconWrapper.lightToDarkHover:hover svg{
    @apply !fill-white;
  }

  .iconWrapper.primaryHoverWhite svg{
    @apply fill-primaryColor;
  }
  .iconWrapper.primaryHoverWhite:hover svg{
    @apply fill-white;
  }

  .iconWrapper.darkHoverPrimary:hover svg{
    @apply fill-primaryColor;
  }

  .iconWrapper.textDarkHoverWhite svg{
    @apply fill-textDark;
  }
  .iconWrapper.textDarkHoverWhite:hover svg{
    @apply fill-white;
  }

  .iconWrapper.dangerOnHoverWhite svg{
    @apply fill-dangerCarpet;
  }
  .iconWrapper.dangerOnHoverWhite:hover svg{
    @apply fill-white;
  }

  .iconWrapper.successHoverWhite svg{
    @apply fill-successCarpet;
  }
  .iconWrapper.successHoverWhite:hover svg{
    @apply fill-white;
  }
  /* iconWrapper-end */

  /* action__btn */
.action__btn {
  @apply w-[26px] h-[26px] p-[4px] rounded-[6px] overflow-hidden shadow-raiseShadow relative duration-300 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-bgWhiteCarpet before:duration-300 hover:before:bg-primaryColor hover:before:opacity-10;
}
.action__btn:hover svg {
  @apply fill-primaryColor duration-300;
}
.action__btn.disable__btn {
  @apply pointer-events-none before:bg-gray-300;
}
.action__btn.disable__btn svg {
  @apply fill-gray-300;
}
/* action__btn__end */

/* action__btn__disabledSD */
.action__btn.action__btn__disabled{
  @apply pointer-events-none before:bg-gray-400 before:opacity-5;
}
.action__btn.action__btn__disabled svg{
  @apply fill-gray-400;
}
/* action__btn__disabledSD__end */

/* action__btn__tertiarySD */
.action__btn.action__btn__tertiary{
  @apply bg-transparent shadow-none before:hidden;
}
.action__btn.action__btn__tertiary:hover svg{
  @apply fill-primaryColor;
}
/* action__btn__tertiarySD__end */

/* action__btn__tertiary__disabledSD */
.action__btn.action__btn__tertiary__disabled{
  @apply pointer-events-none bg-transparent shadow-none before:hidden;
}
.action__btn.action__btn__tertiary__disabled svg{
  @apply fill-gray-300;
}
/* action__btn__tertiary__disabledSD */

/* action__btn__danger */
.action__btn.action__btn__danger{
  @apply hover:before:bg-dangerCarpet cursor-pointer;
}
.action__btn.action__btn__danger:hover svg{
  @apply fill-dangerCarpet;
}
/* action__btn__danger */


/* tippy-tooltip */
.tippy-box {
  border-radius: 8px !important;
  font-size: 12px !important;
  font-family: "Biotif-Medium", sans-serif;
  box-shadow: var(--raiseShadow);
}
.tippy-box .tippy-content {
  padding: 7px 12px;
}
.tippy-box[data-theme="black__tooltipSD"] {
  background-color:var(--textBlackCarpet);
  color: var(--textWhiteCarpet);
}
.tippy-box[data-theme="black__tooltipSD"] .tippy-arrow {
  color: var(--textBlackCarpet);
}
.tippy-box[data-theme="white__tooltipSD"] {
  background-color: var(--bgWhiteCarpet);
  color: var(--textBlackCarpet);
}
.tippy-box[data-theme="white__tooltipSD"] .tippy-arrow {
  color:var(--bgWhiteCarpet);
}
.tippy-box[data-theme="primary__tooltipSD"] {
  background-color: var(--primaryColor);
}
.tippy-box[data-theme="primary__tooltipSD"] .tippy-arrow {
  color: var(--primaryColor);
}
/* tippy-tooltip-end */


/* tippy__dropdown */
[id^="tippy-"] {
  @apply !pointer-events-auto;
}
.tippy-box.tippy__dropdown {
  @apply shadow-mediumFloatShadow py-[9px] bg-bgWhiteCarpet rounded-[6px] min-w-[94px];
}
.tippy-box.tippy__dropdown .tippy-content {
  @apply p-0 min-w-[94px] max-w-[250px];
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul {
  @apply px-[12px] max-h-[270px] overflow-y-auto;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item__link {
  @apply flex items-center w-full text-[14px] font-AcuminPro__Regular text-textSecondary py-[7px] cursor-pointer duration-300 hover:text-primaryColor;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item__link .item__text {
  @apply w-full whitespace-pre overflow-hidden text-ellipsis;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item__link .i__Icon {
  @apply w-[20px] h-[20px] p-[1px] mr-[8px] shrink-0 relative top-[-2px];
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item__link .i__Icon svg {
  @apply fill-textSecondary duration-300;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item__link:hover .i__Icon svg {
  @apply fill-primaryColor;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item:first-child .item__link {
  @apply pt-0;
}
.tippy-box.tippy__dropdown .tippy__dropdown__ul .item:last-child .item__link {
  @apply pb-0;
}
.tippy-box.tippy__dropdown .tippy-arrow {
  @apply hidden;
}
/* tippy__dropdown__end */


/* action__btn.active */
.action__btn.active .i__Icon svg{
  @apply fill-[#ffffff];
}
/* action__btn.active end */

  /* avatar__Img */
  .avatar__Img {
    @apply relative w-[60px] h-[60px];
  }
  .avatar__Img > .inner__wrapper {
    @apply h-full overflow-hidden w-full;
  }
  .img__element {
    @apply w-full relative pt-[100%] overflow-hidden;
  }
  .img__element img {
    @apply block h-full left-0 object-cover object-center absolute top-0 w-full;
  }
  .noImg__icon {
    @apply h-full overflow-hidden p-[24%] relative w-full before:content-[''] before:w-full before:h-full before:left-0 before:top-0 before:bg-primaryColor before:opacity-10 before:absolute;
  }
  .noImg__icon .i__Icon {
    @apply w-full h-full;
  }
  .noImg__icon .iconWrapper svg{
    @apply fill-primaryColor;
  }
  .img__loader__wrapper {
    @apply overflow-hidden pt-[100%] relative w-full before:content-[''] before:w-full before:h-full before:left-0 before:top-0 before:bg-primaryColor before:opacity-10 before:absolute;
  }
  .img__loader__wrapper .inner__wrapper {
    @apply flex items-center justify-center h-full w-full left-0 top-0 absolute z-[2];
  }
  .img__loader__wrapper .smallLoader {
    @apply border-white border-r-primaryColor;
  }
  .noName__letter {
    @apply flex items-center justify-center overflow-hidden pt-[100%] relative w-full before:content-[''] before:w-full before:h-full before:left-0 before:top-0 before:bg-primaryColor before:opacity-10 before:absolute;
  }
  .noName__letter .noName__letter__text {
    @apply text-primaryColor font-AcuminPro__Bold absolute top-[calc(50%_+_2px)] left-[50%] translate-x-[-50%] translate-y-[-50%] uppercase z-[2] whitespace-pre;
  }
  .avatar__Img .online__status {
    @apply bg-successCarpet border-[2px] border-white rounded-full absolute bottom-0 h-[16px] w-[16px] right-[3px] z-[3];
  }
  /* avatar__Img__end */

  /* multiple__avatar__wrapper */
  .multiple__avatar__wrapper {
    @apply flex items-center;
  }
  .multiple__avatar__wrapper .avatar__Img {
    @apply w-[32px] h-[32px] relative cursor-pointer rounded-full border-[2px] border-white ml-[-10px] first:ml-0 last:bg-white hover:z-[3];
  }
  .multiple__avatar__wrapper .avatar__Img .inner__wrapper {
    @apply !rounded-full;
  }
  .multiple__avatar__wrapper .noName__letter {
    @apply hover:before:opacity-100;
  }
  .multiple__avatar__wrapper .noName__letter:hover .noName__letter__text {
    @apply text-white;
  }
  .multiple__avatar__wrapper .noName__letter .noName__letter__text {
    @apply text-[14px] leading-[14px];
  }
  /* multiple__avatar__wrapper__end */

  /* toast */
  .toastWrapper {
    @apply bottom-[15px] max-h-[calc(100dvh_-_60px)] max-w-[calc(100%_-_30px)] overflow-y-auto p-[10px] fixed right-[15px] w-[400px] z-[99];
  }
  .toastWrapper .toastCN__absolute {
    @apply w-full;
  }
  .toastItem {
    @apply shadow-raiseShadow bg-white rounded-[10px] border-[1px] border-black max-w-full relative w-full mb-[12px] last:mb-0 opacity-0 before:content-[''] before:rounded-[10px] before:h-full before:left-0 before:opacity-10 before:absolute before:top-0 before:w-full;
  }
  .toastItem .innerWrapper {
    @apply flex items-center py-[7px] pl-[12px] pr-[7px] relative z-[2];
  }
  .toastItem .toastText {
    @apply text-textDark text-[16px] w-full;
  }
  .toastItem .typeIcon {
    @apply shrink-0 h-[20px] mr-[7px] w-[20px] relative top-[-1px];
  }
  .toastItem .typeIcon svg path {
    @apply fill-textDark;
  }
  .toastItem .closeBtn {
    @apply rounded-full cursor-pointer shrink-0 h-[26px] ml-[8px] p-[7px] duration-300 w-[26px] hover:bg-gray-200;
  }
  .toastItem .closeBtn svg path {
    @apply fill-textDark;
  }
  .toastItem.show {
    @apply animate-toast__show opacity-0;
  }
  .toastItem.success {
    @apply border-successCarpet before:bg-successCarpet;
  }
  .toastItem.success .typeIcon svg path {
    @apply fill-successCarpet;
  }
  .toastItem.success .closeBtn {
    @apply hover:bg-white;
  }
  .toastItem.error {
    @apply border-dangerCarpet before:bg-dangerCarpet;
  }
  .toastItem.error .typeIcon svg path {
    @apply fill-dangerCarpet;
  }
  .toastItem.error .closeBtn {
    @apply hover:bg-white;
  }
  .toastItem.warning {
    @apply border-warningCarpet before:bg-warningCarpet;
  }
  .toastItem.warning .typeIcon svg path {
    @apply fill-warningCarpet;
  }
  .toastItem.warning .closeBtn {
    @apply hover:bg-white;
  }
  /* toast-end */

  /* button-css-start */
  .btnCarpet {
    @apply bg-white inline-flex items-center justify-center rounded-[8px] text-[16px] font-AcuminPro__Regular px-[16px] text-center leading-[18px] py-[10px] h-[40px] duration-300 overflow-hidden relative before:content-[''] before:absolute before:top-0 before:left-0 before:rounded-[8px] before:w-full before:h-full before:bg-transparent before:duration-300 after:content-[''] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:border-[1px] after:border-transparent after:rounded-[8px] after:z-[2] after:duration-300;
  }
  .btnCarpet .btnText {
    @apply relative top-[1px] z-[2] duration-300;
  }
  .btnCarpet .iconWrapper {
    @apply w-[20px] h-[20px] ml-[6px] mr-[-4px] relative z-[2];
  }
  .btnCarpet .iconWrapper svg path {
    @apply duration-300;
  }
  .btnCarpet.iconLeft .iconWrapper {
    @apply mr-[6px] ml-[-2px] order-[0];
  }
  .btnCarpet.iconLeft .btnText {
    @apply order-1;
  }
  .btnCarpet.iconLeft .smallLoader {
    @apply mr-[10px];
  }
  .btnCarpet .smallLoader {
    @apply mr-[8px] p-[5px] top-[-1px];
  }
  .btnCarpet.onlyIcon {
    @apply w-[40px] h-[40px] px-[6px];
  }
  .btnCarpet.onlyIcon .btnText {
    @apply hidden;
  }
  .btnCarpet.onlyIcon .iconWrapper {
    @apply ml-0 mr-0;
  }

  /* primaryBtn */
  .btnCarpet.primaryBtn {
    @apply before:bg-primaryColor hover:before:bg-primaryColorDark;
  }
  .btnCarpet.primaryBtn .iconWrapper svg path {
    @apply fill-white;
  }
  .btnCarpet.primaryBtn .btnText {
    @apply text-white;
  }
  /* primaryBtn-end */

  /* secondaryBtn */
  .btnCarpet.secondaryBtn {
    @apply bg-transparent before:opacity-5 before:bg-primaryColor hover:before:opacity-100;
  }
  .btnCarpet.secondaryBtn .iconWrapper svg path {
    @apply fill-primaryColor;
  }
  .btnCarpet.secondaryBtn .btnText {
    @apply text-primaryColor;
  }
  .btnCarpet.secondaryBtn:hover .iconWrapper svg path {
    @apply fill-white;
  }
  .btnCarpet.secondaryBtn:hover .btnText {
    @apply text-white;
  }
  /* secondaryBtn-end */

  /* primary__ghost__Btn */
  .btnCarpet.primary__ghost__Btn {
    @apply bg-transparent before:bg-transparent before:opacity-5 hover:before:bg-primaryColor;
  }
  .btnCarpet.primary__ghost__Btn .iconWrapper svg path {
    @apply fill-primaryColor;
  }
  .btnCarpet.primary__ghost__Btn .btnText {
    @apply text-primaryColor;
  }
  /* primary__ghost__Btn__end */

  /* primary__outline__Btn */
  .btnCarpet.primary__outline__Btn {
    @apply outline outline-1 outline-primaryColor bg-transparent before:bg-transparent hover:before:bg-primaryColor;
  }
  .btnCarpet.primary__outline__Btn .iconWrapper svg path {
    @apply fill-primaryColor;
  }
  .btnCarpet.primary__outline__Btn .btnText {
    @apply text-primaryColor;
  }
  .btnCarpet.primary__outline__Btn:hover .iconWrapper svg path {
    @apply fill-white;
  }
  .btnCarpet.primary__outline__Btn:hover .btnText {
    @apply text-white;
  }
  /* primary__outline__Btn__end */

  /* dangerBtn */
  .btnCarpet.dangerBtn {
    @apply before:bg-dangerCarpet hover:before:bg-dangerDarkCarpet;
  }
  .btnCarpet.dangerBtn .iconWrapper svg path {
    @apply fill-white;
  }
  .btnCarpet.dangerBtn .btnText {
    @apply text-white;
  }
  /* dangerBtn-end */

  /* dangerSecondaryBtn */
  .btnCarpet.dangerSecondaryBtn {
    @apply bg-transparent before:opacity-5 before:bg-dangerCarpet hover:before:opacity-100;
  }
  .btnCarpet.dangerSecondaryBtn .iconWrapper svg path {
    @apply fill-dangerCarpet;
  }
  .btnCarpet.dangerSecondaryBtn .btnText {
    @apply text-dangerCarpet;
  }
  .btnCarpet.dangerSecondaryBtn:hover .iconWrapper svg path {
    @apply fill-white;
  }
  .btnCarpet.dangerSecondaryBtn:hover .btnText {
    @apply text-white;
  }
  /* dangerSecondaryBtn-end */

  /* danger__ghost__Btn */
  .btnCarpet.danger__ghost__Btn {
    @apply bg-transparent before:bg-transparent before:opacity-5 hover:before:bg-dangerCarpet;
  }
  .btnCarpet.danger__ghost__Btn .iconWrapper svg path {
    @apply fill-dangerCarpet;
  }
  .btnCarpet.danger__ghost__Btn .btnText {
    @apply text-dangerCarpet;
  }
  /* danger__ghost__Btn__end */

  /* danger__outline__Btn */
  .btnCarpet.danger__outline__Btn {
    @apply outline outline-1 outline-dangerCarpet bg-transparent before:bg-transparent hover:before:bg-dangerCarpet;
  }
  .btnCarpet.danger__outline__Btn .iconWrapper svg path {
    @apply fill-dangerCarpet;
  }
  .btnCarpet.danger__outline__Btn .btnText {
    @apply text-dangerCarpet;
  }
  .btnCarpet.danger__outline__Btn:hover .iconWrapper svg path {
    @apply fill-white;
  }
  .btnCarpet.danger__outline__Btn:hover .btnText {
    @apply text-white;
  }
  /* danger__outline__Btn__end */
  /* button-css-end */

  /* form-elements */
  .field__wrapper {
    @apply mb-[20px] w-full;
  }
  .field__wrapper .field__inner__wrapper {
    @apply relative;
  }
  .field__wrapper .label__carpet{
    @apply text-textDark flex font-AcuminPro__Semibold text-[16px] mb-[5px] w-full;
  }
  .input__carpet,
  .textarea__carpet,
  .react-tel-input .form-control{
    @apply bg-white rounded-[8px] border-[1px] border-transparent text-textDark font-AcuminPro__Regular text-[16px] h-[40px] py-[5px] pt-[6px] px-[12px] w-full focus:border-transparent focus:outline focus:outline-1 focus:outline-primaryColor;
  }
  .react-datepicker-wrapper .input__carpet{
    @apply pr-[34px];
  }
 
  .input__carpet ~ .bgWrapper,
  .textarea__carpet ~ .bgWrapper,
  .react-tel-input ~ .bgWrapper,
  .react-datepicker-wrapper ~ .bgWrapper{
    @apply hidden;
  }
  .formElementsBG__field .input__carpet,
  .formElementsBG__field .textarea__carpet{
    @apply bg-formElementsBG;
  }
  .textarea__carpet {
    @apply h-[100px];
  }
  .field__wrapper .field__has__icon .input__carpet,
  .field__wrapper .field__has__icon .textarea__carpet,
  .field__has__icon .react-tel-input .form-control{
    @apply pl-[38px];
  }
  .field__wrapper .field__has__icon .iconWrapper {
    @apply h-[20px] w-[20px] left-[10px] absolute top-[9px] z-[2];
  }
  .field__wrapper .field__has__icon .iconWrapper svg path {
    @apply text-textDark;
  }
  .field__wrapper .field__has__icon.icon__right .iconWrapper {
    @apply left-auto right-[10px];
  }
  .field__wrapper .field__has__icon.icon__right .input__carpet,
  .field__wrapper .field__has__icon.icon__right .textarea__carpet,
  .field__wrapper .field__has__icon.icon__right .react-tel-input .form-control{
    @apply pl-[12px] pr-[40px];
  }
  .field__wrapper.field__has__error .input__carpet,
  .field__wrapper.field__has__error .textarea__carpet,
  .field__wrapper.field__has__error .react-tel-input .form-control{
    @apply outline outline-1 outline-dangerCarpet;
  }
  .field__wrapper.field__has__success .input__carpet,
  .field__wrapper.field__has__success .textarea__carpet,
  .field__wrapper.field__has__success .react-tel-input .form-control{
    @apply outline outline-1 outline-successCarpet;
  }
  .field__wrapper .field__has__icon.icon__right .react-datepicker-wrapper .input__carpet{
    @apply pr-[60px];
  }
  .field__wrapper .field__has__icon.icon__right .react-datepicker-wrapper .react-datepicker__close-icon{
    @apply pr-[37px];
  }

  .helper__text {
    @apply font-AcuminPro__Regular text-[14px] mt-[8px];
  }
  .field__wrapper.field__has__error .helper__text {
    @apply text-dangerCarpet;
  }
  .field__wrapper.field__has__success .helper__text {
    @apply text-successCarpet;
  }

  /* select-box */
.selectCarpet {
  @apply w-full;
}
.selectCarpet div[class$="control"],
.selectCarpet div[class$="control"]:hover,
.selectCarpet .selectCarpet__control,
.selectCarpet .selectCarpet__control:hover{
  @apply min-h-[40px] rounded-[8px] bg-bgWhiteCarpet pt-[4px] pb-[3px] pl-[10px] pr-[38px] border-[1px] border-transparent shadow-none;
}
.selectCarpetBGgray .selectCarpet div[class$="control"],
.selectCarpetBGgray .selectCarpet div[class$="control"]:hover,
.selectCarpetBGgray .selectCarpet .selectCarpet__control,
.selectCarpetBGgray .selectCarpet .selectCarpet__control:hover{
  @apply bg-bgSurface;
}
.selectCarpet div[class$="ValueContainer"],
.selectCarpet .selectCarpet__value-container{
  @apply flex items-center relative h-[30px] w-full p-0 pr-[10px] leading-[30px];
}
.selectCarpet.loaderActive div[class$="ValueContainer"],
.selectCarpet.loaderActive .selectCarpet__value-container{
  @apply pr-[34px];
}
.selectCarpet div[class$="placeholder"],
.selectCarpet .selectCarpet__placeholder {
  @apply text-[16px] font-AcuminPro__Regular text-textDark opacity-100 whitespace-pre overflow-hidden text-ellipsis;
}
.selectCarpet div[class$="singleValue"],
.selectCarpet .selectCarpet__single-value {
  @apply text-[16px] font-AcuminPro__Regular text-textDark opacity-100 whitespace-pre overflow-hidden text-ellipsis;
}
.selectCarpet div[class$="Input"],
.selectCarpet .selectCarpet__input,
.selectCarpet .selectCarpet__input-container {
  @apply absolute top-0 left-0 m-0 h-[30px] w-full p-0 !font-AcuminPro__Regular !text-[18px] !text-textDark;
}
.selectCarpet span[class$="indicatorSeparator"],
.selectCarpet .selectCarpet__indicator-separator {
  @apply hidden;
}
.selectCarpet div[class$="IndicatorsContainer"],
.selectCarpet .selectCarpet__indicators {
  @apply mr-[-28px] cursor-pointer;
}
.selectCarpet div[class$="indicatorContainer"]:last-child,
.selectCarpet .selectCarpet__indicator-separator:last-child {
  @apply p-0 w-[20px] h-[20px] relative before:content-[''] before:absolute before:w-[9px] before:h-[2px] before:bg-textDark before:rounded-[10px] before:rotate-45 before:top-[50%] before:left-[calc(50%_-_3px)] before:translate-x-[-50%] before:translate-y-[-50%] after:content-[''] after:absolute after:w-[9px] after:h-[2px] after:bg-textDark after:rounded-[10px] after:rotate-[-45deg] after:top-[50%] after:left-[calc(50%_+_3px)] after:translate-x-[-50%] after:translate-y-[-50%] hover:before:bg-primaryColor hover:after:bg-primaryColor;
}
.selectCarpet div[class$="indicatorContainer"]:nth-last-of-type(2),
.selectCarpet .selectCarpet__indicator-separator:nth-last-of-type(2){
  @apply p-0 w-[20px] h-[20px] relative cursor-pointer before:content-[''] before:absolute before:w-[12px] before:h-[2px] before:bg-textDark hover:before:bg-dangerCarpet before:rounded-[10px] before:rotate-45 before:top-[50%] before:left-[calc(50%_-_1px)] before:translate-x-[-50%] before:translate-y-[-50%] after:content-[''] after:absolute after:w-[12px] after:h-[2px] after:bg-textDark hover:after:bg-dangerCarpet after:rounded-[10px] after:rotate-[-45deg] after:top-[50%] after:left-[calc(50%_-_1px)] after:translate-x-[-50%] after:translate-y-[-50%];
}
.selectCarpet.loaderActive div[class$="indicatorContainer"]:nth-last-of-type(2),
.selectCarpet.loaderActive .selectCarpet__indicator-separator:nth-last-of-type(2){
  @apply hidden;
}
.selectCarpet div[class$="indicatorContainer"] svg,
.selectCarpet .selectCarpet__indicator-separator svg {
  @apply !opacity-0;
}
.selectCarpet svg[class$="Svg"],
.selectCarpet .selectCarpet__svg {
  @apply fill-textDark opacity-0;
}
.defaultGrayBorder .selectCarpet .selectCarpet__control{
  @apply !outline !outline-1 !outline-borderPrimary;
}
.selectCarpet .selectCarpet__control.selectCarpet__control--menu-is-open,
.selectCarpet .selectCarpet__control.selectCarpet__control--menu-is-open:hover,
.defaultGrayBorder .selectCarpet .selectCarpet__control.selectCarpet__control--menu-is-open,
.defaultGrayBorder .selectCarpet .selectCarpet__control.selectCarpet__control--menu-is-open:hover{
  @apply !outline !outline-1 !outline-primaryColor pt-[4px] pb-[3px];
}

.selectCarpet .selectCarpet__menu {
  @apply shadow-dropDownShadow rounded-[10px] mb-0 mt-[5px] z-[6];
}

/* multi-value-select-box */
.selectCarpet .selectCarpet__value-container.selectCarpet__value-container--is-multi {
  @apply h-auto static flex w-full items-center p-0 pr-[10px];
}
.selectCarpet
  .selectCarpet__value-container.selectCarpet__value-container--is-multi
  .selectCarpet__input-container{
  @apply absolute m-0 p-0 left-0 top-[29px] w-full h-[30px] pl-[14px] pr-[54px] whitespace-pre overflow-hidden text-ellipsis inline-block;
}
.selectCarpet
  .selectCarpet__value-container.selectCarpet__value-container--is-multi
  .selectCarpet__input-container
  .selectCarpet__input {
  @apply static max-w-full;
}
.selectCarpet
  .selectCarpet__value-container.selectCarpet__value-container--is-multi.selectCarpet__value-container--has-value
  .selectCarpet__input-container {
  @apply static w-auto max-w-full pl-0 pr-0 inline-grid;
}
.selectCarpet
  .selectCarpet__value-container.selectCarpet__value-container--is-multi.selectCarpet__value-container--has-value
  .selectCarpet__input-container
  .selectCarpet__input {
  @apply relative max-w-full;
}
.selectCarpet .selectCarpet__multi-value {
  @apply min-h-[26px] inline-flex items-center m-0 mt-[1px] mb-[2px] mr-[4px] bg-bgWhiteCarpet relative rounded-[10px] pt-[4px] pb-[3px] px-[10px] border-[1px] border-primaryColor before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-0 before:rounded-[4px];
}
.selectCarpet .selectCarpet__multi-value .selectCarpet__multi-value__label {
  @apply relative top-[1px] z-[2] p-0 text-[14px] font-AcuminPro__Regular text-textDark leading-[14px] whitespace-pre overflow-hidden text-ellipsis max-w-[100px];
}
.selectCarpet .selectCarpet__multi-value .selectCarpet__multi-value__remove {
  @apply shrink-0 ml-[4px] mr-[-4px] cursor-pointer z-[2] relative p-0 w-[18px] h-[18px] rounded-full before:content-[''] before:absolute before:top-[50%] before:translate-y-[-50%] before:left-[50%] before:translate-x-[-50%] before:w-[10px] before:h-[1px] before:bg-textDark before:rotate-45 after:content-[''] after:absolute after:top-[50%] after:translate-y-[-50%] after:left-[50%] after:translate-x-[-50%] after:w-[10px] after:h-[1px] after:bg-textDark after:-rotate-45 duration-300 hover:bg-hoverLightColor hover:before:bg-primaryColor hover:after:bg-primaryColor;
}
.selectCarpet .selectCarpet__multi-value .selectCarpet__multi-value__remove svg {
  @apply hidden;
}
/* multi-value-select-box-end */

.field__wrapper .selectCarpet .field__has__icon div[class$="control"],
.field__wrapper .selectCarpet .field__has__icon div[class$="control"]:hover,
.field__wrapper .selectCarpet .field__has__icon .selectCarpet__control,
.field__wrapper .selectCarpet .field__has__icon .selectCarpet__control:hover {
  @apply pl-[35px];
}
.field__wrapper
  .selectCarpet
  .field__has__icon
  .selectCarpet__value-container.selectCarpet__value-container--is-multi
  .selectCarpet__input-container {
  @apply pl-[36px];
}
.field__wrapper
  .selectCarpet
  .field__has__icon
  .selectCarpet__value-container.selectCarpet__value-container--is-multi.selectCarpet__value-container--has-value
  .selectCarpet__input-container {
  @apply pl-[3px];
}

.field__wrapper.field__has__error .selectCarpet .selectCarpet__control{
  @apply !outline !outline-1 !outline-dangerCarpet;
}
.field__wrapper.field__has__error .selectCarpet .selectCarpet__control.selectCarpet__control--menu-is-open,
.field__wrapper.field__has__error .selectCarpet .selectCarpet__control.selectCarpet__control--menu-is-open:hover {
  @apply !outline !outline-1 !outline-dangerCarpet pt-[4px] pb-[3px];
}
.field__wrapper.field__has__success .selectCarpet .selectCarpet__control{
  @apply !outline !outline-1 !outline-successCarpet;
}
.field__wrapper.field__has__success .selectCarpet .selectCarpet__control.selectCarpet__control--menu-is-open,
.field__wrapper.field__has__success .selectCarpet .selectCarpet__control.selectCarpet__control--menu-is-open:hover {
  @apply !outline !outline-1 !outline-successCarpet pt-[4px] pb-[3px];
}

/* remove-down-arrow */
.remove__downArrow .selectCarpet div[class$="IndicatorsContainer"],
.remove__downArrow .selectCarpet .selectCarpet__indicators{
  @apply hidden;
}
.remove__downArrow .selectCarpet div[class$="control"],
.remove__downArrow .selectCarpet div[class$="control"]:hover,
.remove__downArrow .selectCarpet .selectCarpet__control,
.remove__downArrow .selectCarpet .selectCarpet__control:hover{
  @apply pr-[10px];
}
.remove__downArrow .selectCarpet .selectCarpet__value-container.selectCarpet__value-container--is-multi{
  @apply pr-[0px];
}
/* remove-down-arrow-end */

/* carperFieldBox */
.field__wrapper.carperFieldBox > .label__carpet,
.field__wrapper.carperFieldBox .selectCarpet > .label__carpet {
  @apply hidden;
}
.field__wrapper.carperFieldBox .input__carpet + .label__carpet,
.field__wrapper.carperFieldBox .textarea__carpet + .label__carpet,
.field__wrapper.carperFieldBox .react-tel-input + .label__carpet,
.field__wrapper.carperFieldBox .react-datepicker-wrapper + .label__carpet{
  @apply block;
}
.field__wrapper.carperFieldBox .input__carpet ~ .bgWrapper,
.field__wrapper.carperFieldBox .textarea__carpet ~ .bgWrapper,
.field__wrapper.carperFieldBox .react-tel-input ~ .bgWrapper,
.field__wrapper.carperFieldBox .react-datepicker-wrapper ~ .bgWrapper{
  @apply block;
}
.field__wrapper.carperFieldBox .textarea__carpet:focus + .label__carpet{
  @apply z-[4] top-[2px] rounded-t-[14px] pt-[10px];
}
.field__wrapper.carperFieldBox.whiteBGTextarea .textarea__carpet:focus + .label__carpet{
  @apply bg-bgWhiteCarpet;
}
.field__wrapper.carperFieldBox .label__carpet {
  @apply w-[calc(100%_-_4px)] px-[13px] text-[14px] text-primaryColor font-AcuminPro__Regular mb-0 absolute left-[2px] top-[12px] z-[2];
}
.field__wrapper.carperFieldBox .textarea__carpet + .label__carpet {
  @apply top-[2px] pt-[10px] z-[3] rounded-t-[16px] bg-bgWhiteCarpet;
}
.field__wrapper.carperFieldBox .bgWrapper{
  @apply absolute top-0 left-0 w-full h-full rounded-[16px];
}
.field__wrapper.carperFieldBox .textarea__carpet ~ .bgWrapper{
  @apply h-[calc(100%_-_10px)];
}
.field__wrapper.carperFieldBox .input__carpet,
.field__wrapper.carperFieldBox .react-tel-input .form-control{
  @apply relative z-[3] h-[68px] border-[2px] border-borderPrimary bg-transparent rounded-[16px] text-[18px] text-textDark font-AcuminPro__Regular pt-[28px] focus:border-primaryColor focus:outline-0 duration-300;
}
.field__wrapper.carperFieldBox .input__carpet:-webkit-autofill,
.field__wrapper.carperFieldBox .input__carpet:-webkit-autofill:hover,
.field__wrapper.carperFieldBox .input__carpet:-webkit-autofill:focus,
.field__wrapper.carperFieldBox .react-tel-input .form-control:-webkit-autofill,
.field__wrapper.carperFieldBox .react-tel-input .form-control:-webkit-autofill:hover,
.field__wrapper.carperFieldBox .react-tel-input .form-control:-webkit-autofill:focus{
  transition: background-color 9999s ease-in-out 0s;
}
.field__wrapper.carperFieldBox.currencyField__wrapper .input__carpet{
  @apply pl-[25px];
}
.field__wrapper.carperFieldBox .textarea__carpet {
  @apply relative z-[3] h-[120px] border-[2px] border-borderPrimary bg-transparent rounded-[16px] text-[18px] text-textDark font-AcuminPro__Regular pt-[28px] focus:border-primaryColor focus:outline-0 duration-300;
}
.field__wrapper.carperFieldBox .field__has__icon .iconWrapper{
  @apply w-[30px] h-[30px] top-[50%] translate-y-[-50%] left-[14px];
}
.field__wrapper.carperFieldBox .field__has__icon .input__carpet,
.field__wrapper.carperFieldBox .field__has__icon .textarea__carpet,
.field__wrapper.carperFieldBox .field__has__icon .react-tel-input .form-control{
  @apply pl-[50px];
}
.field__wrapper.carperFieldBox .field__has__icon .label__carpet{
  @apply pl-[52px];
}
.field__wrapper.carperFieldBox .field__has__icon.icon__right .iconWrapper{
  @apply left-unset right-[15px];
}
.field__wrapper.carperFieldBox .field__has__icon.icon__right .input__carpet,
.field__wrapper.carperFieldBox .field__has__icon.icon__right .textarea__carpet,
.field__wrapper.carperFieldBox .field__has__icon.icon__right .react-tel-input .form-control{
  @apply pl-[15px] pr-[50px];
}
.field__wrapper.carperFieldBox .field__has__icon.icon__right .label__carpet{
  @apply pl-[15px] pr-[52px];
}
.field__wrapper.carperFieldBox.field__has__error .input__carpet,
.field__wrapper.carperFieldBox.field__has__error .textarea__carpet,
.field__wrapper.carperFieldBox.field__has__error .react-tel-input .form-control{
  @apply outline-0 border-dangerCarpet focus:border-dangerCarpet;
}
.field__wrapper.carperFieldBox.field__has__success .input__carpet,
.field__wrapper.carperFieldBox.field__has__success .textarea__carpet,
.field__wrapper.carperFieldBox.field__has__success .react-tel-input .form-control{
  @apply outline-0 border-successCarpet focus:border-successCarpet;
}
.field__wrapper.carperFieldBox .selectCarpet .field__inner__wrapper{
  @apply relative;
}
.field__wrapper.carperFieldBox .selectCarpet .field__inner__wrapper > div:nth-child(1){
  @apply static;
}
.field__wrapper.carperFieldBox .selectCarpet div[class$="control"], 
.field__wrapper.carperFieldBox .selectCarpet div[class$="control"]:hover,
.field__wrapper.carperFieldBox .selectCarpet .selectCarpet__control,
.field__wrapper.carperFieldBox .selectCarpet .selectCarpet__control:hover{
  @apply z-[5] min-h-[68px] h-auto !pt-[28px] bg-transparent border-[2px] border-borderPrimary rounded-[16px];
}
.field__wrapper.carperFieldBox.selectCarpetWhiteBG .selectCarpet div[class$="control"], 
.field__wrapper.carperFieldBox.selectCarpetWhiteBG .selectCarpet div[class$="control"]:hover,
.field__wrapper.carperFieldBox.selectCarpetWhiteBG .selectCarpet .selectCarpet__control,
.field__wrapper.carperFieldBox.selectCarpetWhiteBG .selectCarpet .selectCarpet__control:hover{
  @apply bg-bgWhiteCarpet;
}
.field__wrapper.carperFieldBox .selectCarpet div[class$="placeholder"],
.field__wrapper.carperFieldBox .selectCarpet .selectCarpet__placeholder{
  @apply text-[18px];
}
.field__wrapper .selectCarpet .field__has__icon div[class$="control"],
.field__wrapper .selectCarpet .field__has__icon div[class$="control"]:hover,
.field__wrapper .selectCarpet .field__has__icon .selectCarpet__control,
.field__wrapper .selectCarpet .field__has__icon .selectCarpet__control:hover{
  @apply pl-[48px];
}
.field__wrapper.carperFieldBox .selectCarpet .selectCarpet__control.selectCarpet__control--menu-is-open,
.field__wrapper.carperFieldBox .selectCarpet .selectCarpet__control.selectCarpet__control--menu-is-open:hover{
  @apply !outline-0 border-primaryColor;
}
.field__wrapper.carperFieldBox.field__has__error .selectCarpet .selectCarpet__control,
.field__wrapper.carperFieldBox.field__has__error .selectCarpet .selectCarpet__control:hover{
  @apply !outline-0 border-dangerCarpet;
}
.field__wrapper.carperFieldBox.field__has__success .selectCarpet .selectCarpet__control,
.field__wrapper.carperFieldBox.field__has__success .selectCarpet .selectCarpet__control:hover{
  @apply !outline-0 border-successCarpet;
}

.field__wrapper.carperFieldBox .react-datepicker-wrapper .react-datepicker__close-icon{
  @apply z-[4];
}
.field__wrapper.carperFieldBox .field__has__icon.icon__right .react-datepicker-wrapper .react-datepicker__close-icon{
  @apply pr-[52px];
}
.field__wrapper.carperFieldBox .field__has__icon.icon__right .react-datepicker-wrapper .input__carpet{
  @apply pr-[75px];
}

.field__wrapper.carperFieldBox.whiteBGTextarea .textarea__carpet + .label__carpet{
  @apply pt-[10px] top-[2px] z-[5] bg-bgWhiteCarpet rounded-t-[16px];
}
/* carperFieldBox-end */

  /* colorPicker__Carpet */
  .colorPicker__Carpet {
    @apply inline-flex items-center border-[1px] border-gray-300 rounded-full w-[28px] h-[28px] justify-center p-[2px];
  }
  .colorPicker__Carpet .colorPicker__CarpetInner {
    @apply relative rounded-full cursor-pointer flex items-center justify-center h-full w-full;
  }
  .colorPicker__Carpet .colorPicker__CarpetInner input {
    @apply absolute top-0 left-0 opacity-0 cursor-pointer h-full w-full z-[2];
  }
  .colorPicker__Carpet .colorPicker__CarpetInner .color__picker__img {
    @apply absolute top-0 left-0 w-full h-full object-cover object-center;
  }
  .colorPicker__Carpet .iconWrapper {
    @apply p-[5px];
  }
  .colorPicker__Carpet .iconWrapper svg path {
    @apply fill-white;
  }
  /* colorPicker__Carpet__end */

  /* checkbox */
  /* normal-checkbox */
  .customCheckbox__carpet {
    @apply relative inline-flex items-center;
  }
  .customCheckbox__carpet input {
    @apply absolute top-0 left-0 w-full h-full opacity-0 z-[3] cursor-pointer;
  }
  .customCheckbox__carpet .label__carpet {
    @apply text-[14px] font-AcuminPro__Regular text-textDark relative pl-[26px] mb-0 before:content-[''] before:absolute before:left-[3px] before:top-0 before:w-[16px] before:h-[16px] before:rounded-[4px] before:border-[2px] before:border-borderPrimary before:duration-300;
  }
  .customCheckbox__carpet .iconWrapper {
    @apply w-[10px] h-[10px] absolute top-[3px] left-[6px] opacity-0 duration-300;
  }
  .customCheckbox__carpet .iconWrapper svg path {
    @apply fill-white;
  }
  .customCheckbox__carpet input:hover + .label__carpet {
    @apply before:border-primaryColor;
  }
  .customCheckbox__carpet input:checked + .label__carpet,
  .customCheckbox__carpet.active .label__carpet {
    @apply before:bg-primaryColor before:border-primaryColor;
  }
  .customCheckbox__carpet input:checked ~ .iconWrapper,
  .customCheckbox__carpet.active .iconWrapper {
    @apply opacity-100;
  }
  /* normal-checkbox-end */

  /* medium-checkbox */
  .customCheckbox__carpet.customCheckbox__carpetMedium .label__carpet {
    @apply text-[16px] pl-[28px] before:top-[2px] before:w-[18px] before:h-[18px];
  }
  .customCheckbox__carpet.customCheckbox__carpetMedium .iconWrapper {
    @apply w-[11px] h-[11px] top-[5.3px] left-[6px];
  }
  /* medium-checkbox-end */

  /* large-checkbox */
  .customCheckbox__carpet.customCheckbox__carpetLarge .label__carpet {
    @apply pl-[30px] text-[18px] before:top-0 before:w-[20px] before:h-[20px];
  }
  .customCheckbox__carpet.customCheckbox__carpetLarge .iconWrapper {
    @apply w-[12px] h-[12px] top-[4.3px] left-[6.5px];
  }
  /* large-checkbox-end */

  /* normal-checkbox-without-label */
  .customCheckbox__carpet.without__label {
    @apply text-[0px] w-[16px] h-[16px];
  }
  .customCheckbox__carpet.without__label .label__carpet {
    @apply text-[0px] w-full h-full pl-0 before:top-0 before:left-0;
  }
  .customCheckbox__carpet.without__label .iconWrapper {
    @apply left-[3px];
  }
  /* normal-checkbox-without-label-end */

  /* medium-checkbox-without-label */
  .customCheckbox__carpet.customCheckbox__carpetMedium.without__label
    .label__carpet {
    @apply text-[0px] w-full h-full pl-0 before:top-0 before:left-0;
  }
  .customCheckbox__carpet.customCheckbox__carpetMedium.without__label
    .iconWrapper {
    @apply top-[2.6px] left-[3.2px];
  }
  /* medium-checkbox-without-label-end */

  /* large-checkbox-without-label */
  .customCheckbox__carpet.customCheckbox__carpetLarge.without__label
    .label__carpet {
    @apply text-[0px] w-full h-full pl-0 before:top-0 before:left-0;
  }
  .customCheckbox__carpet.customCheckbox__carpetLarge.without__label
    .iconWrapper {
    @apply top-[3.6px] left-[4.2px];
  }
  /* large-checkbox-without-label-end */

  /* normal-checkbox-with-left-label */
  .customCheckbox__carpet.label__left .label__carpet {
    @apply pl-0 pr-[27px] before:left-unset before:top-[1px] before:right-[3px];
  }
  .customCheckbox__carpet.label__left .iconWrapper {
    @apply left-unset right-[6px] top-[4px];
  }
  /* normal-checkbox-with-left-label-end */

  /* medium-checkbox-with-left-label */
  .customCheckbox__carpet.customCheckbox__carpetMedium.label__left
    .label__carpet {
    @apply pl-0 pr-[30px] before:left-unset before:top-[2px] before:right-[3px];
  }
  .customCheckbox__carpet.customCheckbox__carpetMedium.label__left
    .iconWrapper {
    @apply left-unset right-[7px] top-[5px];
  }
  /* medium-checkbox-with-left-label-end */

  /* large-checkbox-with-left-label */
  .customCheckbox__carpet.customCheckbox__carpetLarge.label__left
    .label__carpet {
    @apply pl-0 pr-[32px] before:left-unset before:top-[2px] before:right-[3px];
  }
  .customCheckbox__carpet.customCheckbox__carpetLarge.label__left .iconWrapper {
    @apply left-unset right-[7px] top-[5.5px];
  }
  /* large-checkbox-with-left-label-end */
  /* checkbox-end */

  /* Radio-button */
  /* normal-radio-button */
  .customRadio__carpet {
    @apply relative inline-flex items-center;
  }
  .customRadio__carpet input {
    @apply absolute top-0 left-0 w-full h-full opacity-0 z-[2] cursor-pointer;
  }
  .customRadio__carpet .label__carpet {
    @apply text-[14px] font-AcuminPro__Regular text-textDark relative pl-[20px] mb-0 before:content-[''] before:absolute before:left-[0px] before:top-[2px] before:w-[14px] before:h-[14px] before:rounded-full before:border-[1px] before:border-borderPrimary after:content-[''] after:absolute after:left-[4px] after:top-[6px] after:h-[6px] after:w-[6px] after:rounded-full after:bg-primaryColor after:opacity-0;
  }
  .customRadio__carpet input:hover + .label__carpet {
    @apply before:border-primaryColor;
  }
  .customRadio__carpet input:checked + .label__carpet {
    @apply before:border-primaryColor after:opacity-100;
  }
  /* normal-radio-button-end */

  /* medium-radio-button */
  .customRadio__carpet.customRadio__carpetMedium .label__carpet {
    @apply text-[16px] pl-[22px] before:w-[16px] before:h-[16px] before:top-[2px] after:w-[8px] after:h-[8px] after:top-[6px] after:left-[4px];
  }
  /* medium-radio-button-end */

  /* large-radio-button */
  .customRadio__carpet.customRadio__carpetLarge .label__carpet {
    @apply text-[18px] pl-[26px] before:w-[18px] before:h-[18px] before:top-[2px] after:w-[8px] after:h-[8px] after:top-[7px] after:left-[5px];
  }
  /* large-radio-button-end */

  /* normal-radio-button-without-label */
  .customRadio__carpet.without__label {
    @apply w-[16px] h-[16px];
  }
  .customRadio__carpet.without__label .label__carpet {
    @apply text-[0px] w-full h-full pl-0 before:top-[1px] before:left-[1px] after:left-[5px] after:top-[5px];
  }
  /* normal-radio-button-without-label-end */

  /* medium-radio-button-without-label */
  .customRadio__carpet.customRadio__carpetMedium.without__label {
    @apply w-[20px] h-[20px];
  }
  .customRadio__carpet.customRadio__carpetMedium.without__label .label__carpet {
    @apply text-[0px] w-full h-full pl-0 before:top-[2px] before:left-[2px] after:left-[6px] after:top-[6px];
  }
  /* medium-radio-button-without-label-end */

  /* large-radio-button-without-label */
  .customRadio__carpet.customRadio__carpetLarge.without__label {
    @apply w-[20px] h-[20px];
  }
  .customRadio__carpet.customRadio__carpetLarge.without__label .label__carpet {
    @apply text-[0px] w-full h-full pl-0 before:top-[1px] before:left-[1px] after:left-[6px] after:top-[6px];
  }
  /* large-radio-button-without-label-end */

  /* normal-radio-button-with-left-label */
  .customRadio__carpet.label__left .label__carpet {
    @apply pl-0 pr-[20px] before:left-unset before:right-0 after:left-unset after:right-[4px];
  }
  /* normal-radio-button-with-left-label-end */

  /* medium-radio-button-with-left-label */
  .customRadio__carpet.customRadio__carpetMedium.label__left .label__carpet {
    @apply pl-0 pr-[24px] before:left-unset before:right-0 after:left-unset after:right-[4px];
  }
  /* medium-radio-button-with-left-label-end */

  /* large-radio-button-with-left-label */
  .customRadio__carpet.customRadio__carpetLarge.label__left .label__carpet {
    @apply pl-0 pr-[26px] before:top-[3px] before:left-unset before:right-0 after:top-[8px] after:left-unset after:right-[5px];
  }
  /* medium-radio-button-with-left-label-end */
  /* Radio-button-end */

  /* toggleSwitch */
  /* normal-toggle-button */
  .toggleSwitch__btn {
    @apply relative inline-flex items-center;
  }
  .toggleSwitch__btn input {
    @apply absolute top-0 left-0 w-full h-full opacity-0 z-[2] cursor-pointer;
  }
  .toggleSwitch__btn .label__carpet {
    @apply text-[16px] font-AcuminPro__Regular text-textDark pl-[44px] relative mb-0 before:content-[''] before:absolute before:top-[0px] before:left-0 before:w-[36px] before:h-[20px] before:bg-gray-300 before:rounded-[50px] before:duration-300 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:w-[16px] after:h-[16px] after:rounded-full after:bg-white after:duration-300;
  }
  .toggleSwitch__btn input:checked + .label__carpet {
    @apply before:bg-primaryColor after:left-[18px] after:bg-white;
  }
  /* normal-toggle-button */

  /* normal-white-toggle-button */
  .toggleSwitch__btn.toggleSwitch__btnWhite .label__carpet {
    @apply before:bg-white after:bg-gray-300;
  }
  .toggleSwitch__btn.toggleSwitch__btnWhite input:checked + .label__carpet {
    @apply before:bg-primaryColor after:left-[18px] after:bg-white;
  }
  /* normal-white-toggle-button-end */

  /* normal-toggle-button-without-label */
  .toggleSwitch__btn.without__label .label__carpet {
    @apply text-[0px] w-[36px] h-[20px] mb-0;
  }
  .toggleSwitch__btn.without__label .label__carpet {
    @apply pl-0;
  }
  /* normal-toggle-button-without-label-end */

  /* normal-toggle-button-with-left-label */
  .toggleSwitch__btn.toggleSwitch__btnLeft .label__carpet {
    @apply pl-0 pr-[46px] before:left-unset before:right-0 after:left-unset after:right-[18px];
  }
  .toggleSwitch__btn.toggleSwitch__btnLeft input:checked + .label__carpet {
    @apply after:right-[2px] after:left-unset;
  }
  /* normal-toggle-button-with-left-label-end */

  /* large-toggle-button */
  .toggleSwitch__btn.toggle__switch__btnSD__large .label__carpet {
    @apply pl-[52px] before:w-[44px] before:h-[24px] before:top-[-1px] after:w-[20px] after:h-[20px] after:top-[1px];
  }
  .toggleSwitch__btn.toggle__switch__btnSD__large
    input:checked
    + .label__carpet {
    @apply after:left-[22px];
  }
  .toggleSwitch__btn.toggle__switch__btnSD__large.toggleSwitch__btnLeft
    .label__carpet {
    @apply pl-0 pr-[52px] after:right-[22px];
  }
  .toggleSwitch__btn.toggle__switch__btnSD__large.toggleSwitch__btnLeft
    input:checked
    + .label__carpet {
    @apply after:left-unset after:right-[2px];
  }
  /* large-toggle-button-end */
  /* toggleSwitch-end */

  /* fileUpload__dropzone */
  .fileUpload__dropzone .closeBtn:hover svg{
    @apply fill-textWhiteCarpet;
  }
  /* fileUpload__dropzone end */
  /* form-elements-end */

  /* modal-start */
  html.modal__open {
    @apply overflow-hidden;
  }
  .modal__wrapper__carpet {
    @apply fixed h-screen top-0 left-0 z-[9999] w-screen items-center justify-center flex;
  }
  .modal__wrapper__carpet .modal__overlay__carpet {
    @apply h-[100vh] pointer-events-none fixed top-0 bottom-0 left-0 right-0 w-screen bg-[#2726354d];
  }
  .modal__wrapper__carpet .modal__content__wrapper {
    @apply relative z-[2] max-w-[calc(100%_-_30px)] overflow-hidden mx-auto rounded-[10px] bg-bgWhiteCarpet sm:fixed sm:top-[50%] sm:translate-y-[-50%] sm:left-[50%] sm:translate-x-[-50%] sm:!w-[calc(100%_-_26px)] sm:max-w-full sm:overflow-visible sm:duration-300 sm:animate-modalMobile__animation;
  }
  .modal__wrapper__carpet .modal__header {
    @apply pt-[11px] pb-[10px] px-[15px] relative z-[4] flex items-center justify-end bg-transparent rounded-t-[10px] sm:py-[14px];
  }
  .modal__wrapper__carpet .modal__header .title {
    @apply text-[18px] font-AcuminPro__Semibold text-textDark w-full pr-[20px];
  }
  .modal__wrapper__carpet .modal__header .modal__close__btn {
    @apply shrink-0 w-[30px] h-[30px] rounded-full text-[0px] relative duration-300 p-[8px] hover:bg-bgWhiteCarpet hover:shadow-raiseShadow sm:absolute sm:top-[-13px] sm:right-[-9px] sm:rounded-full sm:bg-bgWhiteCarpet sm:shadow-raiseShadow sm:w-[34px] sm:h-[34px] sm:p-[9px] sm:hover:bg-dangerCarpet sm:active:bg-dangerCarpet;
  }
  .modal__wrapper__carpet .modal__header .modal__close__btn svg{
    @apply fill-textDark duration-300;
  }
  .modal__wrapper__carpet .modal__header .modal__close__btn:hover svg {
    @apply fill-textBlackCarpet;
  }
  .modal__wrapper__carpet .modal__header .modal__close__btn:hover svg,
  .modal__wrapper__carpet .modal__header .modal__close__btn:active svg{
    @apply sm:fill-[#ffffff];
  }
  .modal__wrapper__carpet .modal__body{
    @apply relative z-[3];
  }
  .modal__wrapper__carpet .modalScroll__wrapper {
    @apply max-h-[calc(100dvh_-_200px)] overflow-y-auto py-[26px] pt-0 px-[30px] md:px-[16px];
  }
  .modal__wrapper__carpet .iconHeader + .modalScroll__wrapper{
    @apply max-h-[calc(100dvh_-_220px)] sm:max-h-[calc(100dvh_-_240px)];
  }
  .modal__wrapper__carpet .modal__body .iconHeader{
    @apply mb-[24px] mt-[-30px] flex items-center justify-center sm:mt-0;
  }
  .modal__wrapper__carpet .modal__body .iconHeader .iconWrapper{
    @apply w-[32px] h-[32px] p-[6px] mr-[8px] relative shrink-0 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10;
  }
  .modal__wrapper__carpet .modal__body .iconHeader .iconHeader__title{
    @apply max-w-full text-[18px] text-textDark font-AcuminPro__Semibold;
  }
  .modal__wrapper__carpet .modal__footer {
    @apply py-[13px] px-[20px] flex items-center justify-end border-t-[1px] border-t-borderPrimary;
  }
  .modal__wrapper__carpet .modal__footer .btnCarpet{
    @apply rounded-[100px] min-w-[100px];
  }
  .modal__wrapper__carpet .modal__footer .btnCarpet.primary__ghost__Btn{
    @apply sm:w-[calc(50%_-_6px)] sm:rounded-[10px] sm:bg-transparent sm:outline sm:outline-[1px] sm:outline-primaryColor sm:before:opacity-100 sm:hover:before:bg-primaryColor;
  }
  .modal__wrapper__carpet .modal__footer .btnCarpet.primary__ghost__Btn:hover .btnText{
    @apply sm:text-[#ffffff];
  }
  .modal__wrapper__carpet .modal__footer .btnCarpet.primaryBtn{
    @apply sm:w-[calc(50%_-_6px)] sm:rounded-[10px] sm:ml-0;
  }

  .modal__wrapper__carpet .iconHeader svg path {
    @apply fill-primaryColor;
  }

  .modal__wrapper__carpet.modalBody__scrollDisable .modalScroll__wrapper{
    @apply px-0 !max-h-unset pb-0 !overflow-visible;
  }

  .modal__wrapper__carpet.formButton__footer .iconHeader + .modalScroll__wrapper{
    @apply max-h-unset px-0 pb-0 overflow-x-hidden;
  }
  .modal__wrapper__carpet.formButton__footer .modalScroll__Own{
    @apply px-[20px] max-h-[calc(100dvh_-_220px)] overflow-y-auto;
  }
  /* modal-end */

  /* alertModal */
  .modal__wrapper__carpet.alertModal .modalScroll__wrapper{
    @apply px-[24px];
  }
  /* alertModal end */
  /* modal-css-end */

  /* carperDropdown */
  .carperDropdown{
    @apply inline-block;
  }
  .carperDropdown .carperDropdown__inner{
    @apply bg-bgWhiteCarpet shadow-dropDownShadow rounded-[16px] py-[10px];
  }
  .carperDropdown .carperDropdown__item .iconWrapper{
    @apply w-[24px] h-[24px] shrink-0 mr-[10px] relative top-[-2px];
  }
  .carperDropdown .carperDropdown__item .carperDropdown__link{
    @apply flex items-center py-[7px] px-[17px] cursor-pointer;
  }
  .carperDropdown .carperDropdown__item .carperDropdown__name{
    @apply inline-block w-full whitespace-pre overflow-hidden text-ellipsis text-[18px] duration-300;
  }
  .carperDropdown .carperDropdown__item .carperDropdown__link:hover svg{
    @apply fill-primaryColor;
  }
  .carperDropdown .carperDropdown__item .carperDropdown__link:hover .carperDropdown__name{
    @apply text-primaryColor;
  }

  .carperDropdown.topHeader__addNew__dropdown .carperDropdown__inner{
    @apply flex flex-wrap;
  }
  .carperDropdown.topHeader__addNew__dropdown .carperDropdown__item{
    @apply w-1/2 sm:w-full;
  }
  .carperDropdown.topHeader__addNew__dropdown .carperDropdown__item .carperDropdown__link{
    @apply py-[10px];
  }
  /* carperDropdown-end */

  /* horizontalTab */
  .horizontalTab .horizontalTab__inner{
    @apply w-full inline-block text-center overflow-x-auto whitespace-pre sm:text-left;
  }
  .horizontalTab .horizontalTab__item{
    @apply inline-block px-[30px] xl:px-[20px];
  }
  .horizontalTab .horizontalTab__link{
    @apply cursor-pointer inline-block relative pb-[8px] before:content-[''] before:absolute before:bottom-0 before:left-[50%] before:translate-x-[-50%] before:w-[0px] before:h-[2px] before:bg-primaryColor before:duration-300;
  }
  .horizontalTab .horizontalTab__link .horizontalTab__name{
    @apply inline-block font-AcuminPro__Semibold text-[18px] duration-300 hover:text-primaryColor;
  }
  .horizontalTab .horizontalTab__item.active .horizontalTab__link .horizontalTab__name{
    @apply text-primaryColor;
  }
  .horizontalTab .horizontalTab__item.active .horizontalTab__link{
    @apply before:w-[40px];
  }
  /* horizontalTab end */

  /* listingPage__header */
  .listingPage__header .action__Btn:hover .iconWrapper svg{
    @apply fill-primaryColor;
  }
  .listingPage__header .action__Btn:hover .btn__name{
    @apply text-primaryColor;
  }
  .listingPage__header .settingSidebar__toggleBtn:hover svg{
    @apply fill-[#ffffff];
  }
  /* listingPage__header end */

  /* customListing__table */
  .customListing__row{
    @apply flex flex-wrap;
  }
  .customListing__header{
    @apply mb-[10px];
  }
  .customListing__header .customListing__row .customListing__cell{
    @apply text-[14px] font-AcuminPro__Semibold text-primaryColor w-[calc(16.66%_-_16px)] px-[15px] py-[8px] last:w-[96px];
  }
  .customListing__body .customListing__row{
    @apply relative mb-[8px] last:mb-0 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-10 before:rounded-[100px];
  }
  .customListing__body .customListing__row .customListing__cell{
    @apply flex items-center text-[16px] font-AcuminPro__Regular text-textDark w-[calc(16.66%_-_16px)] px-[15px] py-[8px] relative z-[2] last:w-[96px];
  }
  /* customListing__table end */

  /* formActionWrapper */
  .formActionWrapper .action__Btn.active .iconWrapper .svgWrapper svg{
    @apply fill-primaryColor;
  }
  .formActionWrapper .action__Btn.active .btn__name{
    @apply text-primaryColor;
  }
  /* formActionWrapper end */
  /* comman-css-end */

  .mainWrapper {
    @apply flex;
  }
  .rightMainWrapper {
    @apply w-[calc(100%_-_260px)] lg:w-full;
  }
  .sidebarCollapse .rightMainWrapper{
    @apply w-[calc(100%_-_78px)] lg:w-full;
  }
  .carpetMainCN__wrapper {
    @apply p-[24px] pb-0 bg-bgSurface rounded-tl-[18px] h-[calc(100dvh_-_82px)] overflow-y-auto overflow-x-hidden lg:px-0 lg:rounded-t-none lg:pt-0 md:h-[calc(100dvh_-_120px)] sm:h-[calc(100dvh_-_114px)];
  }
  .carpetMainCN__wrapper .carpetMainCN__scroll{
    @apply pb-[20px] w-full min-h-[calc(100dvh_-_86px)] h-[calc(100dvh_-_86px)] overflow-y-auto lg:px-[15px] md:min-h-[calc(100dvh_-_126px)] md:h-[calc(100dvh_-_126px)] sm:min-h-[calc(100dvh_-_119px)] sm:h-[calc(100dvh_-_119px)] lg:pb-[15px] lg:pt-0;
  }

  /* topHeader */
  .topHeader {
    @apply flex items-center justify-between py-[20px] px-[20px] 4xl:px-[12px] md:flex-wrap md:py-[12px] lg:relative lg:z-[9] lg:shadow-mediumFloatShadow;
  }

  .topHeader .afterTablet .btnCarpet,
  .topHeader + .lgAddNew .btnCarpet{
    @apply w-[40px] !p-0;
  }
  .topHeader .afterTablet .btnCarpet .btnText,
  .topHeader + .lgAddNew .btnCarpet .btnText{
    @apply hidden;
  }
  .topHeader .afterTablet .btnCarpet .iconWrapper,
  .topHeader + .lgAddNew .btnCarpet .iconWrapper{
    @apply mr-0 ml-0;
  }
  /* topHeader-end */

  /* sidebar */
  .sidebar {
    @apply w-[260px] duration-300 lg:fixed lg:top-0 lg:bottom-0 lg:left-0 lg:bg-bgWhiteCarpet lg:z-[15] lg:rounded-r-[12px] lg:translate-x-[-120%];
  }
  .sidebar .logoWrapper {
    @apply flex items-center justify-center py-[15px] pb-[24px] px-[14px] duration-300;
  }
  .sidebar .logoWrapper img {
    @apply w-[150px] max-w-full;
  }
  .sidebar .logoWrapper img.collapseLogo{
    @apply w-[60px];
  }
  .sidebar .logoWrapper img.collapseLogo{
    @apply hidden;
  }
  .sidebar .menuItem {
    @apply px-[14px] mb-[5px];
  }
  .sidebar .menuItem .menuLink {
    @apply flex items-center w-full px-[14px] py-[10px] rounded-[14px] duration-300 relative overflow-hidden before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-0 before:duration-300 hover:before:opacity-5;
  }
  .sidebar .menuItem .menuLink .iconWrapper {
    @apply shrink-0 mr-[12px] w-[24px] h-[24px] duration-300;
  }
  .sidebar .menuItem .menuLink .iconWrapper svg path {
    @apply duration-300 fill-textDark;
  }
  .sidebar .menuItem .menuLink .menuName {
    @apply text-left w-full whitespace-pre overflow-hidden text-ellipsis text-textDark text-[16px] font-AcuminPro__Semibold duration-300;
  }
  .sidebar .menuItem.active .menuLink {
    @apply bg-primaryColor;
  }
  .sidebar .menuItem.active .menuLink .iconWrapper svg path {
    @apply fill-white;
  }
  .sidebar .menuItem.active .menuLink .menuName {
    @apply text-white;
  }

  .sidebarCollapse .sidebar{
    @apply w-[78px] lg:w-[250px];
  }
  .sidebarCollapse .sidebar .logoWrapper{
    @apply px-0 lg:px-[14px];
  }
  .sidebarCollapse .sidebar .menuItem .menuLink .menuName{
    @apply hidden lg:block;
  }
  .sidebarCollapse .sidebar .menuItem .menuLink{
    @apply w-[44px] h-[44px] p-0 flex items-center justify-center lg:w-full lg:h-auto lg:py-[10px] lg:px-[22px];
  }
  .sidebarCollapse .sidebar .menuItem .menuLink .iconWrapper{
    @apply mr-0 lg:mr-[12px];
  }
  .sidebarCollapse .sidebar .logoWrapper img.fullLogo{
    @apply hidden lg:flex;
  }
  .sidebarCollapse .sidebar .logoWrapper img.collapseLogo{
    @apply block lg:hidden;
  }

  .mobileSidebarCollapse .overlayScreen{
    @apply block;
  }
  .mobileSidebarCollapse .sidebar{
    @apply translate-x-0;
  }
  /* sidebar-end */

  /* authentication pages */
  .backArrow__btn:hover .iconWrapper svg{
    @apply fill-primaryColor;
  }
  /* authentication pages end */

  /* dashboard page */
  .dashboardBox .indicatorUpDown.up svg{
    @apply fill-successCarpet;
  }
  .dashboardBox .indicatorUpDown.down svg{
    @apply fill-dangerCarpet;
  }

  .dashboardBox .input__carpet{
    @apply border-[1px] border-borderPrimary;
  }
  /* dashboard page end */

  /* listing page */
  .listing__page__wrapper .listingPage__header{
    @apply lg:mt-0 lg:rounded-none lg:bg-transparent lg:shadow-none lg:mb-0 lg:pt-[20px] lg:pb-[20px];
  }
  .listing__page__wrapper .listingPage__header .leftWrapper .input__carpet{
    @apply lg:bg-bgWhiteCarpet;
  }
  .listing__page__wrapper .listingPage__header .formActionWrapper{
    @apply lg:bg-bgWhiteCarpet sm:bg-transparent sm:shadow-none;
  }
  .listing__page__wrapper .listingPage__header .formActionWrapper .iconWrapper{
    @apply sm:hover:bg-primaryColor;
  }
  .listing__page__wrapper .listingPage__header .formActionWrapper .iconWrapper:hover svg{
    @apply sm:fill-[#ffffff];
  }
  .listing__page__wrapper .listingPage__header .formActionWrapper .clearBtn .iconWrapper{
    @apply lg:w-[22px] lg:h-[22px] lg:p-[5px] p-[6px] sm:bg-bgWhiteCarpet sm:shadow-raiseShadow hover:bg-gray-200;
  }

  .listing__page__wrapper .listingPage__header + .listing__table{
    @apply lg:px-[15px];
  }
  .listing__page__wrapper .listingPage__header + .listing__table .rdt_TableBody{
    @apply lg:h-[calc(100dvh_-_280px)] md:h-[calc(100dvh_-_316px)];
  }
  /* listing page end */

  /* product listing page */
  .listing__table.product__table{
    @apply lg:px-[15px];
  }
  .listing__page__wrapper .listing__table.product__table .rdt_TableBody{
    @apply lg:h-[calc(100dvh_-_280px)] md:h-[calc(100dvh_-_316px)];
  }
  /* product listing page end */

  /* add team page */
  .availabilityBox .weekLink input:checked + .label{
    @apply text-white before:opacity-100;
  }
  
  .availabilityBox .react-datepicker-wrapper .input__carpet{
    @apply text-[18px] outline outline-[2px] outline-borderPrimary;
  }
  

  .addTeam__page .submitBtn__group{
    @apply justify-end;
  }
  .addTeam__page .submitBtn__group .btnCarpet{
    @apply w-[180px] last:ml-[12px];
  }


  .addTeam__page .horizontalTab{
    @apply relative z-[4] flex justify-start shadow-mediumFloatShadow mb-0 lg:bg-white lg:border-t-[1px] lg:border-t-borderPrimaryLight;
  }
  .addTeam__page .horizontalTab .horizontalTab__inner{
    @apply w-auto max-w-full;
  }
  .addTeam__page .horizontalTab .horizontalTab__item{
    @apply px-0 min-w-[100px] border-r-[1px] border-r-borderPrimaryLight;
  }
  .addTeam__page .horizontalTab .horizontalTab__link{
    @apply w-full py-[13px] px-[20px] duration-300 hover:bg-gray-100;
  }
  .addTeam__page .horizontalTab .horizontalTab__item.active .horizontalTab__link{
    @apply before:w-full;
  }
  .addTeam__page + .submitBtn__group{
    @apply pt-[15px] w-[350px] max-w-full ml-auto lg:px-[14px] sm:w-full sm:z-[10];
  } 
  .addTeam__page .userImage,
  .addTeam__page .userImage div{
    @apply sm:w-full;
  }
  .addTeam__page .userImage{
    @apply mb-[20px];
  }
  .addTeam__page .userImage .fileUpload__dropzone{
    @apply lg:bg-bgWhiteCarpet;
  } 
  /* add team page end */

  /* customerNote__modal */
  .modal__wrapper__carpet.customerNote__modal .modalScroll__wrapper{
    @apply px-0 max-h-unset pb-0 overflow-visible rounded-b-[10px];
  }
  .modal__wrapper__carpet.customerNote__modal .modal__footer{
    @apply hidden;
  }
  .modal__wrapper__carpet.customerNote__modal .writingArea{
    @apply relative;
  }
  .modal__wrapper__carpet.customerNote__modal .writingArea .textarea__carpet{
    @apply resize-none text-[16px] h-[100px] py-[14px] pl-[20px] pr-[65px] outline-0 focus:outline-0 sm:pr-[58px] sm:pl-[12px];
  }
  .modal__wrapper__carpet.customerNote__modal .writingArea .field__has__error .textarea__carpet{
    @apply placeholder:text-dangerCarpet;
  }
  .modal__wrapper__carpet.customerNote__modal .writingArea .field__has__error .field__inner__wrapper{
    @apply border-t-dangerCarpet;
  }
  .modal__wrapper__carpet.customerNote__modal .writingArea .helper__text{
    @apply mt-0 absolute bottom-[5px] pl-[30px] hidden;
  }
  /* customerNote__modal end */

  /* updateNote__modal */
  .updateNote__modal .field__wrapper.carperFieldBox .textarea__carpet{
    @apply h-[200px];
  }
  /* updateNote__modal end */

  /* productPage */
  .productPage{
    @apply xl:pb-[80px] lg:!pt-[75px] lg:z-[9] lg:!px-[15px] lg:!mx-0;
  }
  .productPage .horizontalTab .horizontalTab__item{
    @apply border-r-[1px] border-r-borderPrimaryLight;
  }

  .productPage .attachments__up__wrapper{
    @apply sm:p-[15px] sm:bg-bgWhiteCarpet sm:rounded-[12px] sm:shadow-raiseShadow;
  }

  .productPage .horizontalTab{
    @apply relative flex justify-start shadow-mediumFloatShadow mb-0 z-[4] pr-[54px] lg:mx-0 lg:border-t-[1px] lg:border-t-borderPrimaryLight lg:bg-bgWhiteCarpet lg:fixed lg:top-[81px] lg:left-0 lg:w-full lg:z-[9] md:top-[120px] sm:top-[112px];
  }
  .productPage .horizontalTab .horizontalTab__inner{
    @apply w-auto max-w-full;
  }
  .productPage .horizontalTab .horizontalTab__item{
    @apply px-0 min-w-[100px];
  }
  .productPage .horizontalTab .horizontalTab__link{
    @apply w-full py-[13px] px-[10px] duration-300 hover:bg-gray-100 sm:text-center;
  }
  .productPage .horizontalTab .horizontalTab__item.active .horizontalTab__link{
    @apply before:w-full;
  }

  .productPage .productPage__TabContent{
    @apply p-[24px] h-[calc(100dvh_-_185px)] overflow-y-auto xl:h-auto lg:py-0 lg:px-0 lg:overflow-y-visible;
  }

  .productPage .submitBtn__group{
    @apply absolute bottom-0 right-0 mr-[24px] bg-bgWhiteCarpet rounded-[10px] w-[350px] px-[16px] py-[16px] 3xl:w-[300px] xl:fixed xl:bottom-0 xl:right-0 xl:w-[calc(100%_-_282px)] xl:bg-bgSurface xl:z-[5] xl:px-0 xl:rounded-none xl:justify-end lg:w-full lg:mr-[15px] sm:justify-between sm:mr-0 sm:px-[15px];
  } 
  .productPage .submitBtn__group .btnCarpet{
    @apply xl:w-[170px] xl:last:ml-[10px] sm:w-[calc(100%_-_5px)];
  }

  .customListing__tableWrapper{
    @apply overflow-x-auto;
  }
  .productColor__listing .customListing__table{
    @apply min-w-[1000px] md:min-w-unset;
  }
  .productColor__listing .addNew__colorBtn{
    @apply absolute top-[10px] right-[12px] lg:fixed lg:top-[93px] lg:right-[10px] lg:z-[9] md:top-[132px] sm:top-[124px];
  }
  .productColor__listing .customListing__header .customListing__row .customListing__cell,
  .productColor__listing .customListing__body .customListing__row .customListing__cell{
    @apply first:w-[56px] last:w-[140px];
  }
  .productColor__listing .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .productColor__listing .customListing__body .customListing__row .customListing__cell:nth-child(2){  
    @apply w-[calc(100%_-_796px)]
  }
  .productColor__listing .customListing__header .customListing__row .customListing__cell:nth-child(3),
  .productColor__listing .customListing__body .customListing__row .customListing__cell:nth-child(3),
  .productColor__listing .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .productColor__listing .customListing__body .customListing__row .customListing__cell:nth-child(4),
  .productColor__listing .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .productColor__listing .customListing__body .customListing__row .customListing__cell:nth-child(5),
  .productColor__listing .customListing__header .customListing__row .customListing__cell:nth-child(6),
  .productColor__listing .customListing__body .customListing__row .customListing__cell:nth-child(6){
    @apply w-[150px];
  }

  .customListing__tableWrapper .customListing__table .customListing__header{
    @apply md:hidden;
  }
  .customListing__tableWrapper .customListing__table .customListing__body{
    @apply md:flex md:flex-wrap md:justify-between;
  }
  .customListing__tableWrapper .customListing__table .customListing__row{
    @apply md:w-[calc(50%_-_10px)] md:mb-[20px] md:py-[8px] md:!flex-wrap md:before:rounded-[10px] md:before:bg-bgWhiteCarpet md:before:opacity-100 md:before:shadow-raiseShadow sm:w-full;
  }
  .customListing__tableWrapper .customListing__table .customListing__cell{
    @apply md:!w-full;
  }
  .customListing__tableWrapper .customListing__table .mobileTitle{
    @apply md:inline-block md:font-AcuminPro__Semibold md:mr-[12px];
  } 

  .productPage .productPage__left > div{
    @apply lg:rounded-none lg:bg-transparent;
  }
  /* productPage end */

  /* addColor__modal */
  .modal__wrapper__carpet.addColor__modal .modalScroll__wrapper{
    @apply sm:h-auto sm:overflow-y-visible sm:px-0;
  }
  .addColor__modal .horizontalTab{
    @apply sm:flex sm:mb-0 sm:shadow-mediumFloatShadow sm:mx-0 sm:relative sm:z-[6];
  }
  .addColor__modal .horizontalTab .horizontalTab__link{
    @apply sm:px-[15px] sm:pb-[10px];
  }
  .addColor__modal .horizontalTab .horizontalTab__item.active .horizontalTab__link{
    @apply sm:before:w-full;
  }
  .addColor__modal .horizontalTab .horizontalTab__item{
    @apply sm:px-0;
  }

  .addColor__modal .addColor__tabContent{
    @apply sm:px-[15px] sm:py-[20px] sm:h-[calc(100dvh_-_264px)] sm:overflow-y-auto;
  }

  .modal__wrapper__carpet.addColor__modal .iconHeader + .modalScroll__wrapper{
    @apply max-h-unset px-0 pb-0 overflow-x-hidden;
  }
  .modal__wrapper__carpet.addColor__modal .horizontalTab{
    @apply relative flex justify-start shadow-mediumFloatShadow mb-0 z-[3] pr-[53px] border-t-[1px] border-t-borderPrimaryLight;
  }
  .modal__wrapper__carpet.addColor__modal .horizontalTab .horizontalTab__inner{
    @apply w-auto max-w-full;
  }
  .modal__wrapper__carpet.addColor__modal .horizontalTab .horizontalTab__item{
    @apply px-0 min-w-[100px] border-r-[1px] border-r-borderPrimaryLight;
  }
  .modal__wrapper__carpet.addColor__modal .horizontalTab .horizontalTab__link{
    @apply w-full py-[13px] px-[26px] duration-300 hover:bg-gray-100;
  }
  .modal__wrapper__carpet.addColor__modal .horizontalTab .horizontalTab__item.active .horizontalTab__link{
    @apply before:w-full;
  }

  .modal__wrapper__carpet.addColor__modal .addColor__tabContent{
    @apply p-[20px] max-h-[calc(100dvh_-_270px)] overflow-y-auto overflow-x-hidden md:px-[15px];
  }
  .modal__wrapper__carpet.addColor__modal .addColor__tabContent .addNew__colorBtn{
    @apply absolute top-[12px] right-[9px] z-[5];
  }
  .modal__wrapper__carpet.addColor__modal .productHistory__table{
    @apply bg-transparent p-0 mt-0 rounded-none;
  }
  .modal__wrapper__carpet.addColor__modal .productHistory__table .rdt_TableBody{
    @apply h-auto max-h-[calc(100dvh_-_409px)] md:max-h-[calc(100dvh_-_355px)];
  }

  .modal__wrapper__carpet.addColor__modal .associatedProduct__table .customListing__header .customListing__row .customListing__cell,
  .modal__wrapper__carpet.addColor__modal .associatedProduct__table .customListing__body .customListing__row .customListing__cell{
    @apply w-[calc(50%_-_85px)] first:w-[90px] last:w-[80px];
  }

  .modal__wrapper__carpet.addColor__modal .inventoryTable__wrapper .rdt_Table .rdt_TableBody{
    @apply h-auto max-h-[calc(100dvh_-_478px)];
  }

  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__header .customListing__row .customListing__cell:first-child,
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__body .customListing__row .customListing__cell:first-child{
    @apply w-[60px];
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__header .customListing__row .customListing__cell:last-child,
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__body .customListing__row .customListing__cell:last-child{
    @apply w-[96px];
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__body .customListing__row .customListing__cell:nth-child(2){
    @apply w-[calc(100%_-_742px)];
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__header .customListing__row .customListing__cell:nth-child(3),
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__body .customListing__row .customListing__cell:nth-child(3){
    @apply w-[144px];
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__body .customListing__row .customListing__cell:nth-child(4){
    @apply w-[140px];
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__body .customListing__row .customListing__cell:nth-child(5){
    @apply w-[100px];
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__header .customListing__row .customListing__cell:nth-child(6),
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__body .customListing__row .customListing__cell:nth-child(6){
    @apply w-[100px];
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__header .customListing__row .customListing__cell:nth-child(7),
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__body .customListing__row .customListing__cell:nth-child(7){
    @apply w-[100px];
  }

  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__table .customListing__header{
    @apply md:hidden;
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__table .customListing__body{
    @apply md:flex md:flex-wrap md:justify-between;
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__table .customListing__row{
    @apply md:w-[calc(50%_-_10px)] md:mb-[20px] md:py-[8px] md:!flex-wrap md:before:rounded-[10px] md:before:bg-bgWhiteCarpet md:before:opacity-100 md:before:shadow-raiseShadow sm:w-full;
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__table .customListing__cell{
    @apply md:!w-full;
  }
  .modal__wrapper__carpet.addColor__modal .promotionData__tableWrapper .customListing__table .mobileTitle{
    @apply md:inline-block md:font-AcuminPro__Semibold md:mr-[12px];
  } 

  .modal__wrapper__carpet.addColor__modal .associatedProduct__table .customListing__tableWrapper{
    @apply sm:px-[2px] sm:pt-[2px];
  }
  .modal__wrapper__carpet.addColor__modal .associatedProduct__table .customListing__table .customListing__header{
    @apply sm:hidden;
  }
  .modal__wrapper__carpet.addColor__modal .associatedProduct__table .customListing__table .customListing__body{
    @apply sm:flex sm:flex-wrap sm:justify-between;
  }
  .modal__wrapper__carpet.addColor__modal .associatedProduct__table .customListing__table .customListing__row{
    @apply sm:mb-[20px] sm:py-[8px] sm:!flex-wrap sm:before:rounded-[10px] sm:before:bg-bgSurface sm:before:opacity-100 sm:before:shadow-raiseShadow sm:w-full;
  }
  .modal__wrapper__carpet.addColor__modal .associatedProduct__table .customListing__table .customListing__cell{
    @apply sm:!w-full;
  }
  .modal__wrapper__carpet.addColor__modal .associatedProduct__table .customListing__table .mobileTitle{
    @apply sm:inline-block sm:font-AcuminPro__Semibold sm:mr-[12px];
  }
  /* addColor__modal */

  /* addCustomer__page */
  .addCustomer__page .submitBtn__group{
    @apply justify-end;
  }
  .addCustomer__page .submitBtn__group .btnCarpet{
    @apply w-[180px] last:ml-[12px] sm:w-[calc(50%_-_6px)] sm:last:ml-0;
  }

  .addCustomer__page .horizontalTab{
    @apply relative flex justify-start shadow-mediumFloatShadow mb-0 lg:z-[3] lg:bg-bgWhiteCarpet lg:border-t-[1px] lg:border-t-borderPrimaryLight;
  }
  .addCustomer__page .horizontalTab .horizontalTab__inner{
    @apply w-auto max-w-full;
  }
  .addCustomer__page .horizontalTab .horizontalTab__item{
    @apply px-0 min-w-[100px] border-r-[1px] border-r-borderPrimaryLight;
  }
  .addCustomer__page .horizontalTab .horizontalTab__link{
    @apply w-full py-[13px] px-[20px] duration-300 hover:bg-gray-100;
  }
  .addCustomer__page .horizontalTab .horizontalTab__item.active .horizontalTab__link{
    @apply before:w-full;
  }

  .addCustomer__page .submitBtn__group{
    @apply pt-[18px];
  }

  .customerOrders__table .customListing__header .customListing__row .customListing__cell,
  .customerOrders__table .customListing__body .customListing__row .customListing__cell{
    @apply first:w-[55px] last:w-[75px];
  }
  .customerOrders__table .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .customerOrders__table .customListing__body .customListing__row .customListing__cell:nth-child(2),
  .customerOrders__table .customListing__header .customListing__row .customListing__cell:nth-child(3),
  .customerOrders__table .customListing__body .customListing__row .customListing__cell:nth-child(3),
  .customerOrders__table .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .customerOrders__table .customListing__body .customListing__row .customListing__cell:nth-child(4),
  .customerOrders__table .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .customerOrders__table .customListing__body .customListing__row .customListing__cell:nth-child(5),
  .customerOrders__table .customListing__header .customListing__row .customListing__cell:nth-child(6),
  .customerOrders__table .customListing__body .customListing__row .customListing__cell:nth-child(6),
  .customerOrders__table .customListing__header .customListing__row .customListing__cell:nth-child(7),
  .customerOrders__table .customListing__body .customListing__row .customListing__cell:nth-child(7){
    @apply w-[calc(16.66%_-_22px)];
  }
  .customerDeposit__table .customListing__header .customListing__row .customListing__cell,
  .customerDeposit__table .customListing__body .customListing__row .customListing__cell{
    @apply first:w-[55px] last:w-[75px];
  }
  .customerDeposit__table .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .customerDeposit__table .customListing__body .customListing__row .customListing__cell:nth-child(2),
  .customerDeposit__table .customListing__header .customListing__row .customListing__cell:nth-child(3),
  .customerDeposit__table .customListing__body .customListing__row .customListing__cell:nth-child(3),
  .customerDeposit__table .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .customerDeposit__table .customListing__body .customListing__row .customListing__cell:nth-child(4),
  .customerDeposit__table .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .customerDeposit__table .customListing__body .customListing__row .customListing__cell:nth-child(5),
  .customerDeposit__table .customListing__header .customListing__row .customListing__cell:nth-child(6),
  .customerDeposit__table .customListing__body .customListing__row .customListing__cell:nth-child(6),
  .customerDeposit__table .customListing__header .customListing__row .customListing__cell:nth-child(7),
  .customerDeposit__table .customListing__body .customListing__row .customListing__cell:nth-child(7){
    @apply w-[calc(20%_-_26px)];
  }

  .customerOrders__table,
  .customerDeposit__table{
    @apply w-full overflow-x-auto md:overflow-x-visible;
  }
  .customerOrders__table .customListing__table{
    @apply min-w-[1010px] md:min-w-full;
  }
  .customerDeposit__table .customListing__table{
    @apply min-w-[1000px] md:min-w-full;
  }
  .customerOrders__table .customListing__table .customListing__header,
  .customerDeposit__table .customListing__table .customListing__header{
    @apply md:hidden;
  }
  .customerOrders__table .customListing__table .customListing__body,
  .customerDeposit__table .customListing__table .customListing__body{
    @apply md:flex md:flex-wrap md:justify-between;
  }
  .customerOrders__table .customListing__table .customListing__row,
  .customerDeposit__table .customListing__table .customListing__row{
    @apply md:w-[calc(50%_-_10px)] md:mb-[20px] md:py-[8px] md:!flex-wrap md:before:rounded-[10px] md:before:bg-bgWhiteCarpet md:before:opacity-100 md:before:shadow-raiseShadow sm:w-full;
  }
  .customerOrders__table .customListing__table .customListing__cell,
  .customerDeposit__table .customListing__table .customListing__cell{
    @apply md:!w-full;
  }
  .customerOrders__table .customListing__table .mobileTitle,
  .customerDeposit__table .customListing__table .mobileTitle{
    @apply md:inline-block md:font-AcuminPro__Semibold md:mr-[12px] md:mt-0;
  } 
  /* .customerOrders__table .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .customerOrders__table .customListing__body .customListing__row .customListing__cell:nth-child(2),
  .customerOrders__table .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .customerOrders__table .customListing__body .customListing__row .customListing__cell:nth-child(4),
  .customerOrders__table .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .customerOrders__table .customListing__body .customListing__row .customListing__cell:nth-child(5),
  .customerOrders__table .customListing__header .customListing__row .customListing__cell:nth-child(6),
  .customerOrders__table .customListing__body .customListing__row .customListing__cell:nth-child(6){
    @apply w-[120px];
  } */

  .addCustomer__page .contactInfo + .submitBtn__group{
    @apply fixed bottom-[18px] right-[22px] inline-block w-auto sm:w-full sm:right-0 sm:inline-flex sm:justify-between sm:px-[15px] sm:pt-[13px] sm:bottom-0 sm:pb-[12px];
  }

  .addCustomer__page .productHistory__table{
    @apply p-0 rounded-none bg-transparent mt-0 lg:mt-[18px];
  }
  .addCustomer__page .productHistory__table .rdt_Table .rdt_TableRow{
    @apply !bg-bgSurface lg:!bg-bgWhiteCarpet;
  }
  .addCustomer__page .productHistory__table .rdt_TableBody{
    @apply h-[calc(100dvh_-_324px)] lg:h-[calc(100dvh_-_262px)];
  }
  .addCustomer__page .productHistory__table .rdt_Pagination{
    @apply min-h-unset pt-[12px];
  }
  /* addCustomer__page end */

  
  /* setting-module */
  .setting__sidebar{
    @apply bg-bgWhiteCarpet rounded-[10px] h-[calc(100vh_-_132px)] py-[10px];
  }
  .setting__sidebar>.inner__wrapper>.setting__menu__items>.setting__menu__item{
    @apply mb-[6px];
  }
  .setting__sidebar .setting__menu__link{
    @apply no-underline flex items-center cursor-pointer min-h-[40px] py-[6px] pr-[6px] pl-[10px] relative w-full z-[2];
  }
  .setting__sidebar .setting__menu__text{
    @apply text-textDark inline-block font-AcuminPro__Regular text-[16px] pr-[12px] relative text-left text-ellipsis whitespace-pre w-[calc(100%_-_30px)] z-[2];
  }
  .setting__sidebar .setting__menu__link .down__arrow{
    @apply w-[28px] h-[28px] rounded-full p-[5px] relative duration-300 z-[2];
  }
  .setting__sidebar>.inner__wrapper>.setting__menu__items>.setting__menu__item>.setting__menu__link{
    @apply hover:bg-gray-200;
  }
  .setting__sidebar>.inner__wrapper>.setting__menu__items>.setting__menu__item>.setting__menu__link .down__arrow{
    @apply hover:bg-bgWhiteCarpet;
  }
  .setting__sidebar .setting__menu__items .setting__menu__items.sub__menu{
    @apply pl-[26px] pt-[10px];
  }
  .setting__sidebar .setting__menu__link+.setting__menu__items.sub__menu>.setting__menu__item{
    @apply relative before:content-[''] before:bg-primaryColor before:h-[calc(100%_+_4px)] last:before:h-[calc(100%_-_17px)] before:left-[-11px] before:absolute before:top-[-10px] before:w-[1px];
  }
  .setting__sidebar .setting__menu__items.sub__menu .setting__menu__link{
    @apply relative before:content-[''] before:w-[12px] before:h-[12px] before:absolute before:top-[calc(50%_-_12px)] before:left-[-11px] before:border-l-[1.5px] before:border-l-primaryColor before:border-b-[1.5px] before:border-b-primaryColor before:rounded-bl-[7px];
  }
  .setting__sidebar .setting__menu__items.sub__menu .setting__menu__link.menu__is__open .setting__menu__text{
    @apply text-primaryColor;
  }

  .setting__sidebar>.inner__wrapper>.setting__menu__items>.setting__menu__item>.setting__menu__link.menu__is__open .down__arrow{
    @apply -rotate-180;
  }
  .setting__sidebar>.inner__wrapper>.setting__menu__items>.setting__menu__item>.setting__menu__link.highlight{
    @apply shadow-raiseShadow hover:bg-transparent before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:opacity-30 before:h-full before:bg-primaryColor;
  }
  .setting__sidebar>.inner__wrapper>.setting__menu__items>.setting__menu__item>.setting__menu__link.highlight .setting__menu__text{
    @apply text-primaryColor;
  }
  .setting__sidebar>.inner__wrapper>.setting__menu__items>.setting__menu__item>.setting__menu__link.highlight .down__arrow svg{
    @apply fill-primaryColor;
  }
  .setting__sidebar.desktopCollapse{
    @apply w-[50px] flex items-center justify-center xl:block xl:w-[270px];
  }
  .setting__sidebar.desktopCollapse > .inner__wrapper{
    @apply hidden xl:block;
  }
  .setting__sidebar.desktopCollapse .desktopCollapse__text{
    @apply block;
    writing-mode: vertical-rl;
    text-orientation: mixed;    
  }

  .setting__wrapper .listing__page__wrapper .rdt_TableBody{
    @apply h-[calc(100dvh_-_313px)] lg:h-[calc(100dvh_-_278px)] md:h-[calc(100dvh_-_320px)];
  }

  .setting__wrapper{
    @apply xl:overflow-hidden xl:!flex-nowrap xl:relative xl:pb-[5px] xl:pr-[3px];
  }
  .setting__wrapper .setting__sidebar{
    /* @apply xl:duration-300 xl:absolute xl:top-0 xl:left-0 xl:z-[8] xl:h-[calc(100vh_-_122px)] xl:translate-x-[-110%]; */
    @apply xl:duration-300 xl:shrink-0 xl:ml-[-285px] xl:mr-[15px] lg:mr-0 lg:fixed lg:top-0 lg:left-0 lg:z-[14] lg:rounded-l-none lg:h-[100dvh];
  }
  .setting__wrapper.sidebarOpen .setting__sidebar{
    @apply xl:ml-0;
  }
  .setting__wrapper .setting__panel__wrapper{
    @apply xl:w-full xl:ml-0;
  } 
  .setting__wrapper .setting__sidebar.desktopCollapse ~ .setting__panel__wrapper{
    @apply w-[calc(100%_-_80px)] xl:w-full;
  }
  .setting__wrapper .listingPage__header{
    @apply xl:pl-[50px] lg:pb-[20px];
  }
  .setting__wrapper .listingPage__header .settingSidebar__toggleBtn{
    @apply xl:flex;
  }
  .setting__wrapper.sidebarOpen .listingPage__header .settingSidebar__toggleBtn .svgWrapper{
    @apply rotate-180;
  }
  .setting__wrapper .listing__table{
    @apply lg:px-[14px];
  }
  .setting__wrapper .listingPage__header .leftWrapper{
    @apply sm:w-[calc(100%_-_40px)];
  }
  .setting__wrapper.sidebarOpen .responsiveOverlay{
    @apply lg:block;
  }
  .setting__wrapper.sidebarOpen .responsiveSidebarClose__btn{
    @apply lg:left-[280px];
  }
  /* setting-module end */

  /* filterModal */
  .filterModal.modal__wrapper__carpet .modalScroll__wrapper{
    @apply lg:px-[20px];
  }
  .filterModal .radioWrapper .field__wrapper{
    @apply flex flex-wrap items-center;
  }
  .filterModal .radioWrapper .field__wrapper > .label__carpet{
    @apply w-full text-[18px] capitalize mb-[10px];
  }
  .filterModal .radioWrapper .customRadio__carpetSingle{
    @apply inline-flex mr-[20px] last:mr-0;
  } 
  .filterModal .radioWrapper .customRadio__carpet .label__carpet{
    @apply block;
  }
  /* filterModal */

  /* order page */
  .orderPage + .submitBtn__group{
    @apply pt-[20px];
  }
  .orderPage .addressHeader .horizontalTab .horizontalTab__item{
    @apply px-0 w-1/2 border-r-[1px] border-r-borderPrimaryLight last:border-r-0;
  }
  .orderPage .addressHeader .horizontalTab .horizontalTab__link{
    @apply w-full py-[13px] px-[10px] duration-300 hover:bg-gray-100 sm:text-center;
  }
  .orderPage .addressHeader .horizontalTab .horizontalTab__item.active .horizontalTab__link{
    @apply before:w-full;
  }

  .orderPage .detailsTab__header .horizontalTab .horizontalTab__item{
    @apply px-0 w-[150px] border-r-[1px] border-r-borderPrimaryLight last:border-r-0;
  }
  .orderPage .detailsTab__header .horizontalTab .horizontalTab__link{
    @apply w-full py-[13px] px-[10px] text-center duration-300 hover:bg-gray-100 sm:px-[16px];
  }
  .orderPage .detailsTab__header .horizontalTab .horizontalTab__item.active .horizontalTab__link{
    @apply before:w-full;
  }

  .orderPage .orderDetailsTab__wrapper{
    @apply lg:bg-bgWhiteCarpet lg:rounded-[12px];
  }
  /* order line item table */
  .orderLineItem__table{
    @apply w-full overflow-x-auto md:overflow-x-visible;
  }
  .orderLineItem__table .customListing__table{
    @apply min-w-[1270px] md:min-w-full;
  }
  .orderLineItem__table .customListing__header .customListing__row,
  .orderLineItem__table .customListing__body .customListing__row{
    @apply items-center;
  } 
  .orderLineItem__table .customListing__header .customListing__row .customListing__cell,
  .orderLineItem__table .customListing__body .customListing__row .customListing__cell{
    @apply first:w-[60px] last:w-[130px] inline-block;
  }
  .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(2){
    @apply w-[70px];
  }
  .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(3),
  .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(3),
  .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(4){
    @apply w-[175px];
  }
  .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(5),
  .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(6),
  .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(6){
    @apply w-[140px];
  }
  .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(7),
  .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(7),
  .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(8),
  .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(8){
    @apply w-[120px];
  }
  .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(9),
  .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(9){
    @apply w-[140px];
  }

  .orderLineItem__table .customListing__table .customListing__header{
    @apply md:hidden;
  }
  .orderLineItem__table .customListing__table .customListing__body{
    @apply md:flex md:flex-wrap md:justify-between;
  }
  .orderLineItem__table .customListing__table .customListing__row{
    @apply md:w-[calc(50%_-_10px)] md:mb-[20px] md:py-[8px] md:!flex-wrap md:before:rounded-[10px] md:before:bg-bgSurface md:before:opacity-100 md:before:shadow-raiseShadow sm:w-full;
  }
  .orderLineItem__table .customListing__table .customListing__cell{
    @apply md:!w-full;
  }
  .orderLineItem__table .customListing__table .mobileTitle{
    @apply md:inline-block md:font-AcuminPro__Semibold md:mr-[12px];
  } 

  .purchaseOrderPage .orderLineItem__table .customListing__table{
    @apply min-w-[1432px] md:min-w-full;
  }
  .purchaseOrderPage .orderLineItem__table .customListing__header .customListing__row .customListing__cell:first-child,
  .purchaseOrderPage .orderLineItem__table .customListing__body .customListing__row .customListing__cell:first-child{
    @apply w-[70px];
  }
  .purchaseOrderPage .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .purchaseOrderPage .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(2){
    @apply w-[160px];
  }
  .purchaseOrderPage .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(3),
  .purchaseOrderPage .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(3){
    @apply w-[160px];
  }
  .purchaseOrderPage .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .purchaseOrderPage .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(4){
    @apply w-[130px];
  }
  .purchaseOrderPage .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .purchaseOrderPage .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(5){
    @apply w-[150px];
  }
  .purchaseOrderPage .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(6),
  .purchaseOrderPage .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(6){
    @apply w-[130px];
  }
  .purchaseOrderPage .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(7),
  .purchaseOrderPage .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(7){
    @apply w-[150px];
  }
  .purchaseOrderPage .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(8),
  .purchaseOrderPage .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(8){
    @apply w-[200px];
  }
  .purchaseOrderPage .orderLineItem__table .customListing__header .customListing__row .customListing__cell:nth-child(9),
  .purchaseOrderPage .orderLineItem__table .customListing__body .customListing__row .customListing__cell:nth-child(9){
    @apply w-[150px];
  }
  .purchaseOrderPage .orderLineItem__table .customListing__header .customListing__row .customListing__cell:last-child,
  .purchaseOrderPage .orderLineItem__table .customListing__body .customListing__row .customListing__cell:last-child{
    @apply w-[130px];
  }

  .purchaseOrderPage .orderLineItem__table .customListing__table .customListing__row{
    @apply md:w-full;
  }
  /* order line item table end */

  /* installDate__table */
  .installDate__table{
    @apply w-full overflow-x-auto md:overflow-x-visible;
  }
  .installDate__table .customListing__table{
    @apply min-w-[1000px] md:min-w-full;
  }
  .installDate__table .customListing__header .customListing__row,
  .installDate__table .customListing__body .customListing__row{
    @apply flex items-center;
  }
  .installDate__table .customListing__header .customListing__row .customListing__cell,
  .installDate__table .customListing__body .customListing__row .customListing__cell{
    @apply inline-block whitespace-pre overflow-hidden text-ellipsis;
  }
  .installDate__table .customListing__header .customListing__row .customListing__cell:first-child,
  .installDate__table .customListing__body .customListing__row .customListing__cell:first-child{
    @apply w-[60px];
  }
  .installDate__table .customListing__header .customListing__row .customListing__cell:last-child,
  .installDate__table .customListing__body .customListing__row .customListing__cell:last-child{
    @apply w-[100px];
  }
  .installDate__table .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .installDate__table .customListing__body .customListing__row .customListing__cell:nth-child(2){
    @apply w-[calc(50%_-_200px)];
  }
  .installDate__table .customListing__header .customListing__row .customListing__cell:nth-child(3),
  .installDate__table .customListing__body .customListing__row .customListing__cell:nth-child(3){
    @apply w-[calc(50%_-_200px)];
  }
  .installDate__table .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .installDate__table .customListing__body .customListing__row .customListing__cell:nth-child(4){
    @apply w-[120px];
  }
  .installDate__table .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .installDate__table .customListing__body .customListing__row .customListing__cell:nth-child(5){
    @apply w-[120px];
  }

  .installDate__table .customListing__table .customListing__header{
    @apply md:hidden;
  }
  .installDate__table .customListing__table .customListing__body{
    @apply md:flex md:flex-wrap md:justify-between;
  }
  .installDate__table .customListing__table .customListing__row{
    @apply md:w-[calc(50%_-_10px)] md:mb-[20px] md:py-[8px] md:!flex-wrap md:before:rounded-[10px] md:before:bg-bgSurface md:before:opacity-100 md:before:shadow-raiseShadow sm:w-full;
  }
  .installDate__table .customListing__table .customListing__cell{
    @apply md:!w-full;
  }
  .installDate__table .customListing__table .mobileTitle{
    @apply md:inline-block md:font-AcuminPro__Semibold md:mr-[12px];
  } 
  /* installDate__table end */

  /* order deposit table */
  .orderDeposit__table{
    @apply w-full overflow-x-auto md:overflow-x-visible;
  }
  .orderDeposit__table .customListing__table{
    @apply min-w-[850px] md:min-w-full;
  }
  .orderDeposit__table .customListing__header .customListing__row .customListing__cell,
  .orderDeposit__table .customListing__body .customListing__row .customListing__cell,
  .orderDeposit__table .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .orderDeposit__table .customListing__body .customListing__row .customListing__cell:nth-child(2),
  .orderDeposit__table .customListing__header .customListing__row .customListing__cell:nth-child(3),
  .orderDeposit__table .customListing__body .customListing__row .customListing__cell:nth-child(3),
  .orderDeposit__table .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .orderDeposit__table .customListing__body .customListing__row .customListing__cell:nth-child(4),
  .orderDeposit__table .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .orderDeposit__table .customListing__body .customListing__row .customListing__cell:nth-child(5){
    @apply first:w-[calc(20%_-_19.3px)] w-[calc(20%_-_19.3px)];
  }
  .orderDeposit__table .customListing__header .customListing__row .customListing__cell:last-child,
  .orderDeposit__table .customListing__body .customListing__row .customListing__cell:last-child{
    @apply w-[96px];
  }

  .orderDeposit__table .customListing__table .customListing__header{
    @apply md:hidden;
  }
  .orderDeposit__table .customListing__table .customListing__body{
    @apply md:flex md:flex-wrap md:justify-between;
  }
  .orderDeposit__table .customListing__table .customListing__row{
    @apply md:w-[calc(50%_-_10px)] md:mb-[20px] md:py-[8px] md:!flex-wrap md:before:rounded-[10px] md:before:bg-bgSurface md:before:opacity-100 md:before:shadow-raiseShadow sm:w-full;
  }
  .orderDeposit__table .customListing__table .customListing__cell{
    @apply md:!w-full;
  }
  .orderDeposit__table .customListing__table .mobileTitle{
    @apply md:inline-block md:font-AcuminPro__Semibold md:mr-[12px];
  } 
  /* order deposit table end */

  /* addProductOrder__modal */ 
  .modal__wrapper__carpet.formButton__footer.addProductOrder__modal .modalScroll__Own{
    @apply sm:px-[14px];
  }
  .modal__wrapper__carpet.formButton__footer.addProductOrder__modal .modal__footer{
    @apply sm:px-[10px];
  }
  .modal__wrapper__carpet.addProductOrder__modal .modal__footer .btnCarpet.primary__ghost__Btn,
  .modal__wrapper__carpet.addProductOrder__modal .modal__footer .btnCarpet.secondaryBtn,
  .modal__wrapper__carpet.addProductOrder__modal .modal__footer .btnCarpet.primaryBtn{
    @apply sm:w-[calc(33.3333%_-_6px)];
  }
  .modal__wrapper__carpet.addProductOrder__modal .modal__footer .btnCarpet{
    @apply sm:rounded-[10px];
  }
  /* addProductOrder__modal end */

  /* costReview__modal */
  .costReview__modal .modal__footer__right {
    @apply sm:justify-end;
  }
  .costReview__table__wrapper{
    @apply w-full overflow-x-auto md:overflow-x-visible;
  }
  .costReview__table__wrapper .costReview__table{
    @apply min-w-[850px] md:min-w-full;
  }
  .customListing__header .customListing__row .customListing__cell,
  .costReview__table .customListing__body .customListing__row .customListing__cell{
    @apply first:w-[200px] last:w-[100px];
  }
  .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .costReview__table .customListing__body .customListing__row .customListing__cell:nth-child(2),
  .customListing__header .customListing__row .customListing__cell:nth-child(3),
  .costReview__table .customListing__body .customListing__row .customListing__cell:nth-child(3),
  .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .costReview__table .customListing__body .customListing__row .customListing__cell:nth-child(4),
  .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .costReview__table .customListing__body .customListing__row .customListing__cell:nth-child(5){
    @apply w-[calc(25%_-_75px)];
  }
  .costReview__table .customListing__body .customListing__row:last-child{
    @apply before:hidden md:before:block;
  }
  .costReview__table .customListing__body .customListing__row:last-child .customListing__cell{
    @apply font-AcuminPro__Semibold;
  }

  .costReview__table__wrapper .customListing__table .customListing__header{
    @apply md:hidden;
  }
  .costReview__table__wrapper .customListing__table .customListing__body{
    @apply md:flex md:flex-wrap md:justify-between;
  }
  .costReview__table__wrapper .customListing__table .customListing__row{
    @apply md:w-[calc(50%_-_10px)] md:mb-[20px] md:py-[8px] md:!flex-wrap md:before:rounded-[10px] md:before:bg-bgWhiteCarpet md:before:opacity-100 md:before:shadow-raiseShadow sm:w-full;
  }
  .costReview__table__wrapper .customListing__table .customListing__cell{
    @apply md:!w-full;
  }
  .costReview__table__wrapper .customListing__table .mobileTitle{
    @apply md:inline-block md:font-AcuminPro__Semibold md:mr-[12px];
  } 
  /* costReview__modal end */

  /* installerPayment__modal */
  .installerPayment__tableWrapper{
    @apply w-full overflow-x-auto md:overflow-x-visible;
  }
  .installerPayment__tableWrapper .subVariant__wrapper{
    @apply min-w-[1364px] md:min-w-full;
  } 
  /* installerPayment__modal end */

  /* addUpdateProductItem__modal */
  /* addUpdateProductItem__modal end */

  /* addressModal */
  .modal__wrapper__carpet .modalScroll__wrapper{
    @apply px-[15px];
  }
  .modal__wrapper__carpet .modalScroll__wrapper .fieldCol{
    @apply md:w-full;
  }
  /* addressModal end */

  /* viewInventory__modal */
  .viewInventory__modal .inventoryTable__wrapper .rdt_Table .rdt_TableBody{
    @apply max-h-[calc(100dvh_-_414px)] overflow-y-auto;
  }
  .modal__wrapper__carpet.viewInventory__modal .modalScroll__wrapper{
    @apply pb-[10px];
  }
  /* viewInventory__modal end */

  /* order page end */

  /* Order Calendar page design start */
  .calendarTop__header .datePicker__cs__wrapper .react-datepicker-wrapper{
    @apply opacity-0;
  }
  .calendarTop__header .datePicker__cs__wrapper .label__carpet,
  .calendarTop__header .datePicker__cs__wrapper .bgWrapper,
  .calendarTop__header .datePicker__cs__wrapper .iconWrapper{
    @apply !hidden;
  }
  .calendarTop__header .datePicker__cs__wrapper .field__wrapper.carperFieldBox .input__carpet,
  .calendarTop__header .datePicker__cs__wrapper .field__wrapper.carperFieldBox .react-tel-input .form-control{
    @apply !h-[27px] !p-0 cursor-pointer;
  }
  .calendarTop__header .selectCarpet__control{
    @apply !bg-[#f4f4f6];
  }

  .orderCalendar__tableWrapper{
    @apply w-full overflow-x-auto relative lg:overflow-x-visible;
  }
  .orderCalendar__table{
    @apply min-w-[1170px];
  }

  .orderRow{
    @apply flex;
  }
  .orderCalendar__head{
    @apply pb-[15px] z-[6];
  }
  .orderRow .orderCell{
    @apply px-[6px] w-[calc(14.28%_-_35px)];
  }
  .orderCalendar__head .orderRow .orderCell{
    @apply first:pl-0 last:pr-0;
  }
  .orderCalendar__head .orderRow .orderCell .innerWrapper{
    @apply bg-bgWhiteCarpet h-full text-center rounded-[10px] px-[10px] py-[10px];
  }
  .orderRow .orderCell.installer .innerWrapper,
  .orderRow .orderCell.timeCode .innerWrapper{
    @apply flex items-center justify-start;
  }
  .orderRow .orderCell.installer .innerWrapper .title,
  .orderRow .orderCell.timeCode .innerWrapper .title{
    @apply text-[18px] font-AcuminPro__Semibold;
  }
  .orderRow .orderCell.weekDay .dayName{
    @apply text-[16px] font-AcuminPro__Regular text-textDark;
  }
  .orderRow .orderCell.weekDay .dayDate{
    @apply text-[20px] font-AcuminPro__Bold text-textDark;
  }
  .orderRow .orderCell.installer{
    @apply w-[245px];
  }
  .orderRow .orderCell.timeCode{
    @apply w-[127px];
  }

  .orderCalendar__body{
    @apply h-[calc(100dvh_-_294px)] overflow-y-auto lg:h-[calc(100dvh_-_279px)];
  }
  .orderCalendar__body .orderCalendar__body__inner{
    @apply bg-bgWhiteCarpet rounded-[10px] overflow-hidden lg:overflow-visible;
  }
  .orderCalendar__body .orderRow{
    @apply border-b-[1px] border-b-borderPrimary last:border-b-0;
  }
  .orderCalendar__body .orderRow .orderCell{
    @apply px-[6px] py-[6px] border-r-[1px] border-r-borderPrimary last:border-r-0 4xl:px-[6px];
  }
  .orderCalendar__body .orderRow .orderCell.installer,
  .orderCalendar__body .orderRow .orderCell.timeCode{
    @apply px-[10px];
  }

  .orderBox{
    @apply relative rounded-[10px] overflow-hidden border-[1px] border-[#5348d499] p-[10px] mb-[6px] last:mb-0 before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-5;
  }
  .orderBox .innerBox{
    @apply relative z-[2];
  }

  .orderDetails__modal .customListing__header .customListing__row .customListing__cell,
  .orderDetails__modal .customListing__body .customListing__row .customListing__cell{
    @apply first:w-[16%] w-[21%];
  }

  .calendarTable__wrapper{
    @apply overflow-x-auto overflow-y-auto h-[calc(100dvh_-_200px)] md:h-[calc(100dvh_-_224px)] sm:h-[calc(100dvh_-_262px)];
  }
  .calendarTable__wrapper table{
    @apply w-full min-w-[1170px];
  }
  .calendarTable__wrapper table thead{
    @apply relative z-[4];
  }
  .calendarTable__wrapper table thead th{
    @apply bg-bgSurface px-[6px] first:w-[245px] pb-[16px] sticky top-0 first:left-0 first:z-[4] first:pl-0 last:pr-0 sm:first:w-[150px];
  }
  .calendarTable__wrapper table thead .innerWrapper{
    @apply min-h-[74px] bg-bgWhiteCarpet h-full text-center rounded-[10px] px-[10px] py-[10px];
  }
  .calendarTable__wrapper table thead .dayName{
    @apply text-[16px] font-normal font-AcuminPro__Regular text-textDark;
  }
  .calendarTable__wrapper table thead .dayDate{
    @apply text-[20px] font-normal font-AcuminPro__Bold text-textDark;
  }
  .calendarTable__wrapper table tbody td{
    @apply bg-bgWhiteCarpet py-[6px] px-[10px] border-b-[1px] border-b-borderPrimary border-r-[1px] border-r-borderPrimary first:border-r-0 first:sticky first:z-[3] first:left-0 last:border-r-0 first:before:content-[''] first:before:absolute first:before:top-0 first:before:right-0 first:before:h-[calc(100%_+_1px)] first:before:w-[1px] first:before:bg-borderPrimary first:before:z-[3] sm:first:w-[150px];
  }
  .calendarTable__wrapper table tbody tr:last-child td{
    @apply border-b-0;
  }
  /* Order Calendar page design end */

  /* Pending Assign Inventory*/
  .pendingAssign__Inventory{
    @apply w-full overflow-x-auto;
  }
  .pendingAssign__Inventory .customListing__table{
    @apply min-w-[1552px];
  }
  .pendingAssign__Inventory .customListing__header .customListing__row,
  .pendingAssign__Inventory .customListing__body .customListing__row{
    @apply flex items-center;
  }
  .pendingAssign__Inventory .customListing__header .customListing__row .customListing__cell,
  .pendingAssign__Inventory .customListing__body .customListing__row .customListing__cell{
    @apply inline-block whitespace-pre overflow-hidden text-ellipsis first:w-[115px] last:w-[170px];
  }
  .pendingAssign__Inventory .customListing__header .customListing__row .customListing__cell:nth-child(9),
  .pendingAssign__Inventory .customListing__body .customListing__row .customListing__cell:nth-child(9){
    @apply w-[170px];
  }
  .pendingAssign__Inventory .customListing__header .customListing__row .customListing__cell:nth-child(8),
  .pendingAssign__Inventory .customListing__body .customListing__row .customListing__cell:nth-child(8){
    @apply w-[150px];
  }
  .pendingAssign__Inventory .customListing__header .customListing__row .customListing__cell:nth-child(7),
  .pendingAssign__Inventory .customListing__body .customListing__row .customListing__cell:nth-child(7){
    @apply w-[150px];
  }
  .pendingAssign__Inventory .customListing__header .customListing__row .customListing__cell:nth-child(6),
  .pendingAssign__Inventory .customListing__body .customListing__row .customListing__cell:nth-child(6){
    @apply w-[160px];
  }
  .pendingAssign__Inventory .customListing__header .customListing__row .customListing__cell:nth-child(5),
  .pendingAssign__Inventory .customListing__body .customListing__row .customListing__cell:nth-child(5){
    @apply w-[160px];
  }
  .pendingAssign__Inventory .customListing__header .customListing__row .customListing__cell:nth-child(4),
  .pendingAssign__Inventory .customListing__body .customListing__row .customListing__cell:nth-child(4){
    @apply w-[150px];
  }
  .pendingAssign__Inventory .customListing__header .customListing__row .customListing__cell:nth-child(3),
  .pendingAssign__Inventory .customListing__body .customListing__row .customListing__cell:nth-child(3){
    @apply w-[160px];
  }
  .pendingAssign__Inventory .customListing__header .customListing__row .customListing__cell:nth-child(2),
  .pendingAssign__Inventory .customListing__body .customListing__row .customListing__cell:nth-child(2){
    @apply w-[160px];
  }
  /* Pending Assign Inventor end */

  /* pricingModal */
  .modal__wrapper__carpet.pricingModal .modal__footer .primaryBtn{
    @apply sm:ml-auto;
  }
  /* pricingModal end */
}

/* normal-css */
/* comman-css start */

/* tooltip-css-start */
.tippy-box .tippy-content{
  @apply py-[7px] px-[12px] relative z-[1];
}
.tippy-arrow{
  @apply w-[16px] h-[16px] before:content-[''] before:absolute before:border-[8px] before:border-solid before:border-transparent;
}
.tippy-box[data-placement^=top]>.tippy-arrow{
  @apply bottom-0;
}
.tippy-box[data-placement^='top']>.tippy-arrow{
  @apply before:border-b-0 before:bottom-[-7px] before:left-0 before:origin-center;
}
.tippy-box[data-theme=black__tooltipSD]{
  @apply bg-bgBlackCarpet text-textWhiteCarpet text-[14px] font-AcuminPro__Regular rounded-[8px];
}
.tippy-box[data-theme=black__tooltipSD] .tippy-arrow{
  @apply text-textBlackCarpet;
}
.tippy-box[data-placement^='top'][data-theme=black__tooltipSD]>.tippy-arrow{
  @apply before:border-t-bgBlackCarpet;
}
.tippy-box[data-theme=white__tooltipSD]{
  @apply shadow-dropDownShadow bg-bgWhiteCarpet text-textBlackCarpet text-[14px] font-AcuminPro__Regular rounded-[8px];
}
.tippy-box[data-theme=white__tooltipSD] .tippy-arrow{
  @apply text-bgWhiteCarpet;
}
.tippy-box[data-placement^='top'][data-theme=white__tooltipSD]>.tippy-arrow{
  @apply before:border-t-bgWhiteCarpet;
}
.tippy-box[data-theme=primary__tooltipSD]{
  @apply bg-primaryColor text-textWhiteCarpet text-[14px] font-AcuminPro__Regular rounded-[8px];
}
.tippy-box[data-theme=primary__tooltipSD] .tippy-arrow{
  @apply text-primaryColor;
}
.tippy-box[data-placement^='top'][data-theme=primary__tooltipSD]>.tippy-arrow{
  @apply before:border-t-primaryColor;
}
/* tooltip-css-end */

/* number field */
.react-tel-input .special-label{
  display:none;
} 
/* number field end */

/* react datepicker */
.react-datepicker-wrapper,
.react-datepicker__input-container{
  @apply w-full;
}
.react-datepicker-wrapper .react-datepicker__close-icon{
  padding-right:10px;
}
.react-datepicker-wrapper .react-datepicker__close-icon:after{
  @apply bg-textBlackCarpet;
}
.react-datepicker-wrapper .react-datepicker__close-icon:hover:after{
  @apply bg-primaryColor;
}

.react-datepicker-popper{
  z-index:6 !important;
}
.react-datepicker-popper .react-datepicker{
  background-color:var(--bgWhiteCarpet);
  border:none;
  box-shadow:var(--raiseShadow);
}
.react-datepicker .react-datepicker__triangle{
  display:none;
}
.react-datepicker .react-datepicker__header{
  padding:12px 0px 0px 0px;
  position:relative;
  background-color:transparent;
  border-bottom:none;
}
.react-datepicker .react-datepicker__current-month{
  text-align:left;
  font-family: "AcuminPro-Semibold";
  font-size:18px;
  color:var(--textBlackCarpet);
  padding-left:12px;
  padding-right:60px;
}
.react-datepicker > .react-datepicker__navigation{
  width:24px;
  height:24px;
  box-shadow:var(--raiseShadow);
  border-radius:4px;
  background-color:var(--bgWhiteCarpet);
  position:absolute;
  top:12px;
  right:12px;
  transition:0.5s;
}
.react-datepicker > .react-datepicker__navigation:hover{
  background-color:var(--bgBlackCarpet);
}
.react-datepicker > .react-datepicker__navigation::before{
  content:"";
  position:absolute;
  top:50%;
  left:calc(50% - 2px);
  transform:translate(-50%,-50%) rotate(-135deg);
  width:8px;
  height:8px;
  border-left:2px solid var(--borderBlackCarpet);
  border-bottom:2px solid var(--borderBlackCarpet);
}
.react-datepicker > .react-datepicker__navigation.react-datepicker__navigation--previous{
  left:unset;
  right:44px;
}
.react-datepicker > .react-datepicker__navigation.react-datepicker__navigation--previous::before{
  left:calc(50% + 2px);
  transform:translate(-50%,-50%) rotate(45deg);
}
.react-datepicker > .react-datepicker__navigation:hover::before{
  border-left:2px solid var(--borderWhiteCarpet);
  border-bottom:2px solid var(--borderWhiteCarpet);
}
.react-datepicker > .react-datepicker__navigation .react-datepicker__navigation-icon{
  display:none;
}

.react-datepicker__header__dropdown{
  display:flex;
  align-items:center;
  background-color:var(--formElementsBG);
  margin-top:12px;
  margin-bottom:4px;
}
.react-datepicker .react-datepicker__month-dropdown-container,
.react-datepicker .react-datepicker__year-dropdown-container{
  width:50%;
  margin:0px;
  position: relative;
}
.react-datepicker .react-datepicker__month-dropdown-container select,
.react-datepicker .react-datepicker__year-dropdown-container select{
  padding:7px 0px;
  background-color:transparent;
}
.react-datepicker .react-datepicker__month-read-view,
.react-datepicker .react-datepicker__year-read-view{
  display:flex;
  align-items:center;
  justify-content:center;
  padding:8px 5px;
  visibility:visible !important;
}
.react-datepicker .react-datepicker__month-read-view--selected-month,
.react-datepicker .react-datepicker__year-read-view--selected-year{
  font-family: "AcuminPro-Regular";
  font-size:15px;
  color:var(--textDark);
  order:-1;
  line-height: 16px;
}
.react-datepicker .react-datepicker__month-read-view--down-arrow,
.react-datepicker .react-datepicker__year-read-view--down-arrow{
  border-width:2px 2px 0 0;
  border-color:var(--textDark) !important;
  position:static;
  margin-top:-8px;
  margin-left:8px;
}
.react-datepicker .react-datepicker__month-dropdown{
  background-color:var(--formElementsBG);
  border:none;
  border-radius:0px;
  left:0px;
  width:100%;
}
.react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option{
  font-size:15px;
  color:var(--textDark);
  text-align:left;
  padding:5px 10px;
  border-radius:0px !important;
  position:relative;
}
.react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option:hover{
  background-color:var(--bgWhiteCarpet);
}
.react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option--selected{
  position:absolute;
  top:4px;
  right:10px;
  left:unset;
}
.react-datepicker .react-datepicker__month-dropdown .react-datepicker__month-option.react-datepicker__month-option--selected_month{
  background-color:var(--primaryColor);
  color:#ffffff;
}

.react-datepicker .react-datepicker__year-dropdown{
  background-color:var(--formElementsBG);
  border:none;
  border-radius:0px;
  left:0px;
  width:100%;
  height: 251px;
}
.react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option{
  font-size:15px;
  color:var(--textDark);
  text-align:left;
  padding:5px 10px;
  border-radius:0px !important;
  position:relative;
}
.react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option:hover{
  background-color:var(--bgWhiteCarpet);
}
.react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option--selected{
  position:absolute;
  top:4px;
  right:10px;
  left:unset;
}
.react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option.react-datepicker__year-option--selected_year{
  background-color:var(--primaryColor);
  color:#ffffff;
}
.react-datepicker .react-datepicker__navigation--years{
  top:0px;
  width:20px;
  height:20px;
  position: relative;
}
.react-datepicker .react-datepicker__navigation--years::before{
  content:"";
  position:absolute;
  left: 5px;
  top: 7px;
  width:10px;
  height:10px;
  border-left:2px solid var(--textDark);
  border-bottom:2px solid var(--textDark);
  transform:rotate(135deg);
}
.react-datepicker .react-datepicker__navigation--years.react-datepicker__navigation--years-previous:before{
  transform:rotate(-45deg);
}

.react-datepicker .react-datepicker__day-name,
.react-datepicker .react-datepicker__day,
.react-datepicker .react-datepicker__time-name{
  width:30px;
  height:30px;
  font-family: "AcuminPro-Regular";
  color:var(--textDark);
  font-size:16px;
  display:inline-flex;
  align-items:center;
  justify-content:center;
  border-radius:4px;
  margin:2px;
  padding-top:2px;
}
.react-datepicker .react-datepicker__day-name:hover,
.react-datepicker .react-datepicker__day:hover,
.react-datepicker .react-datepicker__time-name:hover{
  background-color:var(--hoverLightColor);
  border-radius:4px;
}
.react-datepicker .react-datepicker__day-name.react-datepicker__day--today,
.react-datepicker .react-datepicker__day.react-datepicker__day--today,
.react-datepicker .react-datepicker__time-name.react-datepicker__day--today,
.react-datepicker .react-datepicker__day-name.react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__day.react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__time-name.react-datepicker__day--keyboard-selected{
  color:var(--primaryColor);
  font-weight:normal;
  font-family: "AcuminPro-Regular";
  border-radius:4px;
  outline:1px solid var(--primaryColor);
  background-color:transparent;
}
.react-datepicker .react-datepicker__day-name.react-datepicker__day--today:hover,
.react-datepicker .react-datepicker__day.react-datepicker__day--today:hover,
.react-datepicker .react-datepicker__time-name.react-datepicker__day--today:hover,
.react-datepicker .react-datepicker__day-name.react-datepicker__day--keyboard-selected:hover,
.react-datepicker .react-datepicker__day.react-datepicker__day--keyboard-selected:hover,
.react-datepicker .react-datepicker__time-name.react-datepicker__day--keyboard-selected:hover{
  background-color:var(--hoverLightColor);
}
.react-datepicker .react-datepicker__day-name.react-datepicker__day--selected,
.react-datepicker .react-datepicker__day.react-datepicker__day--selected,
.react-datepicker .react-datepicker__time-name.react-datepicker__day--selected{
  background-color:var(--primaryColor);
  color:var(--textWhiteCarpet);
  font-weight:normal;
  font-family: "AcuminPro-Regular";
  border-radius:4px;
}
.react-datepicker .react-datepicker__day-name{
  font-family: "AcuminPro-Semibold";
  color:var(--textBlackCarpet);
}
.react-datepicker .react-datepicker__day-name:hover{
  background-color:transparent;
}
.react-datepicker__day--in-range{
  background-color:var(--primaryColor) !important;
  color:#ffffff !important;
}

.react-datepicker.react-datepicker--time-only .react-datepicker__header{
  padding-top:10px;
  padding-bottom:6px;
}
.react-datepicker-popper .react-datepicker.react-datepicker--time-only .react-datepicker__time-container,
.react-datepicker-popper .react-datepicker.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width:120px;
}
.react-datepicker-popper .react-datepicker.react-datepicker--time-only .react-datepicker__time-container .react-datepicker-time__header{
  padding-right:12px;
  font-family: "AcuminPro-Semibold";
}
.react-datepicker-popper .react-datepicker.react-datepicker--time-only .react-datepicker__time-list-item {
  font-family: "AcuminPro-Semibold";
  font-size:14px;
  color:var(--textDark);
  padding:5px 10px 6px 10px !important;
  height:auto !important;
}
.react-datepicker-popper .react-datepicker.react-datepicker--time-only .react-datepicker__time-list-item:hover{
  background-color:var(--formElementsBG) !important;
}
.react-datepicker-popper .react-datepicker.react-datepicker--time-only .react-datepicker__time-list-item.react-datepicker__time-list-item--selected,
.react-datepicker-popper .react-datepicker.react-datepicker--time-only .react-datepicker__time-list-item.react-datepicker__time-list-item--selected:hover{
  background-color:var(--primaryColor) !important;
  color:var(--textWhiteCarpet) !important;
}
/* react datepicker end */

 /* rdt_Table */
 .customRdtTable__Wrapper > div{
  padding:4px;
 }
 .rdt_Table,
 .rdt_Table .rdt_TableHeadRow,
 .rdt_Table .rdt_TableRow{
  background-color:transparent !important;
}
.rdt_Table .rdt_TableHeadRow,
.rdt_Table .rdt_TableRow{
  border-bottom:none !important;
  /* min-height:44px; */
  min-height:unset;
}
.rdt_Table .rdt_TableCol{
  font-size:16px;
  font-family: "AcuminPro-Semibold";
  color:var(--textDark);
  padding:10px 15px;
}
.rdt_Table .rdt_TableCell{
  font-size:16px;
  font-family: "AcuminPro-Regular";
  color:var(--textDark);
  padding:10px 15px;
}
.rdt_Table .rdt_TableCell > div{
  width:100%;
  white-space:normal !important;
}
.rdt_Table input[type="checkbox"]{
  width:16px;
  height:16px;
  border:1px solid var(--borderPrimary);
  background-color:#ffffff;
  accent-color: var(--primaryColor);
  position: relative;
  top:-1px;
}
.rdt_Table .rdt_TableCol_Sortable{
  display:flex;
  align-items:center;
  justify-content:flex-start;
}
.rdt_Table .rdt_TableCol_Sortable > div:first-child{
  max-width:100%;
  white-space:pre;
  overflow:hidden;
  text-overflow:ellipsis;
}
.rdt_Table .rdt_TableCol_Sortable:hover{
  opacity:1;
}
.rdt_Table .rdt_TableCol_Sortable span,
.rdt_Table .rdt_TableCol_Sortable div{
  opacity:1 !important;
}
.rdt_Table .rdt_TableCol_Sortable > span{
  flex-shrink:0;
  opacity:0 !important;
  pointer-events:none;
}
.rdt_Table .rdt_TableCol_Sortable:hover > span{
  opacity:1 !important;
  pointer-events:auto;
}
.rdt_Table .rdt_TableCol_Sortable .iconWrapper .svgWrapper svg{
  fill:var(--primaryColor);
  opacity:1 !important;
  width:100%;
  height:100%;
}
.rdt_Table .rdt_TableCol_Sortable .iconWrapper .svgWrapper svg path{
  opacity:1 !important;
}
.rdt_Table .rdt_TableCol_Sortable .iconWrapper{
  width:14px;
  height:14px;
  margin-left:5px;
}
.rdt_Pagination{
  padding-left:0px !important;
  padding-right:0px !important;
  border-top:none !important;
  background-color:transparent !important;
}
.rdt_Pagination > span:nth-child(1){
  font-family: "AcuminPro-Regular";
  color:var(--textDark);
  font-size:16px;
  margin:0px 0px;
}
.rdt_Pagination > span:nth-child(3){
  font-family: "AcuminPro-Regular";
  color:var(--textDark);
  font-size:16px;
  margin:0px 20px;
}
.rdt_Pagination > div:nth-child(2){
  background-color:var(--bgWhiteCarpet);
  border:1px solid var(--borderPrimary);
  border-radius:5px;
  margin-left:8px;
}
.rdt_Pagination select{
  font-family: "AcuminPro-Regular";
  color:var(--textDark);
  font-size:16px;
  outline:none;
}
.rdt_Pagination > div:nth-child(2) svg{
  fill:var(--textDark);
}
.rdt_Pagination button{
  width:34px;
  height:34px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right:4px;
}
.rdt_Pagination button:last-child{
  margin-right:0px;
}
.rdt_Pagination button:hover{
  background-color:var(--hoverLightColor) !important;
}
.rdt_Pagination button svg{
  fill:var(--textDark) !important;
}
.rdt_Pagination button[disabled]{
  opacity:0.4;
  pointer-events:none;
}

.teamData__tabel .rdt_Table .rdt_TableBody{
  height:calc(100dvh - 380px);
  overflow-y:auto;
}
.rdt_Table .avatar__Img.addBtn{
  width:30px;
  height:30px;
}
.rdt_Table .avatar__Img .noImg__icon{
  padding:5px;
}
.rdt_Table .avatar__Img.addBtn .iconWrapper{
  width:calc(100% + 6px);
  height:calc(100% + 6px);
  margin-left:-3px;
  margin-top:-3px; 
  padding:3px;
}
.rdt_Table .avatar__Img.addBtn .noImg__icon{
  padding:0px;
}
.rdt_Table .avatar__Img.addBtn .noImg__icon::before{
  background-color:transparent;
}
.rdt_Table .avatar__Img.addBtn .noImg__icon:hover::before{
  background-color:var(--primaryColor);
  opacity:1;
}
.rdt_Table .avatar__Img.addBtn .noImg__icon:hover svg{
  fill:var(--textWhiteCarpet);
}
.rdt_Table .avatar__Img.addBtn .noImg__icon:hover .iconWrapper{
  padding:0px;
}

.rdt_Table .rdt_TableHeadRow{
  border-radius:8px;
  background-color: #e4e7f2 !important;
  border: 1px solid #e4e7f2 !important;
  margin-bottom:10px;
}
.rdt_Table .rdt_TableRow{
  border-radius:8px;
  /* border:1px solid var(--borderPrimary) !important; */
  background-color:var(--bgWhiteCarpet) !important;
  margin-bottom:8px;
}
.rdt_Table .rdt_TableRow:last-child{
  margin-bottom:0px;
}

.rdt_Table .rdt_TableCell .actionBtn__wrapper{
  display:flex;
  justify-content:flex-start;
  align-items:center;
  width:100%;
}
/* rdt_Table end */

/* listing__page */
.listing__page__wrapper .rdt_TableBody{
  height:calc(100dvh - 312px);
  overflow-y:auto;
}
/* listing__page__end */

/* responsiveDataTable */
.responsiveDataTable .rdt_TableBody{
  @apply md:!h-auto;
}
.responsiveDataTable{
  @apply md:!overflow-y-visible;
}
.responsiveDataTable > div{
  @apply md:!block;
}
.responsiveDataTable .rdt_TableHead{
  @apply md:!hidden;
}
.responsiveDataTable .rdt_TableRow{
  @apply md:!flex md:!flex-wrap md:items-center md:!justify-start md:p-[14px] md:pb-[3px] md:mb-[12px];
}
.responsiveDataTable .rdt_Table .rdt_TableCell{
  @apply md:flex-wrap md:py-0 md:px-0 md:w-full md:min-w-full md:max-w-full md:flex-none md:justify-start md:mb-[7px];
}
.responsiveDataTable .rdt_Table .rdt_TableCell{
  @apply md:before:content-[attr(data-column-id)] md:before:relative md:before:top-[-1px] md:before:mr-[10px] md:before:inline-block md:before:mb-[7px] md:before:text-[14px] md:before:text-textDark md:before:font-AcuminPro__Semibold md:before:shrink-0;
}
.responsiveDataTable .rdt_Table .rdt_TableCell > input{
  @apply md:mb-[7px];
}
.responsiveDataTable .rdt_Table .rdt_TableCell > div{
  @apply md:w-auto md:max-w-full md:mb-[7px] md:empty:hidden;
}
.responsiveDataTable .rdt_Table .rdt_TableCell > *{
  @apply md:inline-block;
}
.responsiveDataTable .rdt_Table .rdt_TableCell div{
  @apply md:!whitespace-normal md:!overflow-visible;
}
.responsiveDataTable .rdt_Table .rdt_TableCell .avatar__Img,
.responsiveDataTable .rdt_Table .rdt_TableCell .iconWrapper{
  @apply md:relative md:top-[-2px];
} 

.productHistory__table .responsiveDataTable .rdt_Table .rdt_TableCell{
  @apply first:before:hidden;
}

.listing__page__wrapper .responsiveDataTable{
  @apply md:ml-[-15px] md:w-[calc(100%_+_30px)];
}
.listing__page__wrapper .responsiveDataTable .rdt_TableBody{
  @apply md:!h-[calc(100dvh_-_256px)] md:overflow-y-auto md:px-[15px] sm:!h-[calc(100dvh_-_252px)];
}

.lead__table .responsiveDataTable .rdt_Table .rdt_TableCell .addSalesPersonBtn .avatar__Img .inner__wrapper{
  @apply md:!rounded-full md:!overflow-hidden;
}
.lead__table .responsiveDataTable .rdt_Table .rdt_TableCell:nth-last-child(2),
.lead__table .responsiveDataTable .rdt_Table .rdt_TableCell:last-child{
  @apply md:inline-flex md:items-center md:inline-block md:w-auto md:min-w-unset md:max-w-unset;
}
.lead__table .responsiveDataTable .rdt_Table .rdt_TableCell:last-child{
  @apply md:ml-[14px];
}

.order__table .responsiveDataTable .rdt_Table .rdt_TableCell .avatar__Img .inner__wrapper,
.salesCommission__table .responsiveDataTable .rdt_Table .rdt_TableCell .avatar__Img .inner__wrapper,
.team__table .responsiveDataTable .rdt_Table .rdt_TableCell .avatar__Img .inner__wrapper,
.openOrder__table .responsiveDataTable .rdt_Table .rdt_TableCell .avatar__Img .inner__wrapper{
  @apply md:!rounded-full md:!overflow-hidden;
}

.addCustomer__page  .productHistory__table .responsiveDataTable{
  @apply md:ml-[-15px] md:w-[calc(100%_+_30px)];
}
.addCustomer__page  .productHistory__table .responsiveDataTable .rdt_TableBody{
  @apply md:!h-[calc(100dvh_-_247px)] md:px-[15px] sm:!h-[calc(100dvh_-_240px)];
}

.addColor__modal .productHistory__table .rdt_Pagination{
  @apply min-h-unset pt-[10px];
}
.addColor__modal  .productHistory__table .responsiveDataTable{
  @apply md:ml-[-15px] md:w-[calc(100%_+_30px)];
}
.addColor__modal  .productHistory__table .responsiveDataTable .rdt_TableBody{
  @apply md:px-[15px];
}
.addColor__modal .productHistory__table .rdt_Table .rdt_TableRow{
  @apply md:!bg-bgSurface;
}
/* responsiveDataTable end */
/* comman-css end */

/* productPage */
.productHistory__table .rdt_TableBody{
  height:calc(100dvh - 520px);
  overflow-y:auto;
}
/* productPage */

/* select box dropdown menu */
body .selectCarpet__menu-portal {
  @apply !z-[9999];
}
.selectCarpet__menu-list {
  @apply rounded-[8px] overflow-x-hidden overflow-y-auto max-h-[250px];
}
.selectCarpet__option {
  @apply font-AcuminPro__Regular text-[16px] text-textDark py-[7px] px-[14px] cursor-pointer last:border-b-0 first:rounded-t-[8px] last:rounded-b-[8px];
}
.selectCarpet__option.selectCarpet__option--is-focused {
  @apply bg-transparent text-textBlackCarpet;
}
.selectCarpet__option.selectCarpet__option--is-selected,
.selectCarpet__option.selectCarpet__option--is-selected.selectCarpet__option--is-focused {
  @apply bg-transparent text-primaryColor;
}
.selectCarpet__menu-notice.selectCarpet__menu-notice--no-options {
  @apply text-[16px] font-AcuminPro__Regular text-textDark py-[8px] px-[12px] text-center;
}
/* select box dropdown menu */
/* normal-css end */

/* invoiceAddFormPage */
.invoiceAddFormPage .submitBtn__group{
  @apply !pt-[20px] justify-end lg:px-[16px] sm:justify-between;
}
.invoiceAddFormPage .submitBtn__group .btnCarpet{
  @apply w-[150px] last:ml-[12px] sm:last:ml-0 sm:w-[calc(50%_-_6px)];
}
/* invoiceAddFormPage end */

.arAgingReport__modal .rdt_Table{
  @apply min-w-[2500px] md:min-w-full;
}
.arAgingReport__modal .responsiveDataTable .rdt_TableRow{
  @apply !bg-bgSurface;
}

.removeLabel div span{display: none;}
.pdfPreview canvas{margin:0 auto;}
.pdfPreview {position: relative;}
.page-controls {position: absolute;bottom: 0%;left: 50%;background: #fff;opacity: 1;transform: translate(-50%);transition: opacity ease-in-out .2s;box-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);border-radius: 4px;z-index: 2}
.page-controls span {font-size: 16px;padding: 10px;}
.page-controls button {width: 44px;height: 44px;background: #fff;border: 0;font: inherit;font-size: 20px;}
.page-controls button:hover {cursor: pointer; background-color: #e6e6e6}
.page-controls button:disabled{color: #c6c0c0;}